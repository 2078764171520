import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { CAR_NOTE_TYPES } from "../../util/common";

import "./CarNotes.scss";

const CarNotes = ({ car, t }) => {
  const { notes } = car;

  const carDmsNotes = notes?.filter(n => n.car_note_type_id === CAR_NOTE_TYPES.DMS_NOTE);

  return (
    <div className="CarDetailContainer">
      <h3>{t("dms_notes").message || "DMS Notes"}</h3>
      {!carDmsNotes?.length && <div className="NoDataAvailable">{t("no_data_available").message || "No data available"}</div>}
      {carDmsNotes?.length > 0 &&
        carDmsNotes.map(({ dms_car_note_id, created_on, note }) => (
          <div className="note-container" key={dms_car_note_id}>
            <div>{moment(created_on).format("DD-MM-YYYY HH:mm")}</div>
            <div>{note}</div>
          </div>
        ))}
    </div>
  );
};

export default withTranslation()(CarNotes);
