import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Grid, Icon, List, Message, Modal } from "semantic-ui-react";

import { FloatingActions, SubHeader, UserMenuActionsPortal, UserInput } from "../../components";
import EditChecklist from "./EditChecklist";
import { GLOBAL_ACTION_TYPES } from "../App/store.js";

import Service from "./service";

import "./Checklists.css";

class Checklists extends Component {
  state = {
    originalTags: [],
    tags: [],
    selectedTags: [],
    locationChecklists: [],
    originalLocationChecklists: [],
    loadingLocationChecklists: false,
    dealerChecklists: [],
    originalDealerChecklists: [],
    loadingDealerChecklists: false,
    defaultChecklists: [],
    originalDefaultChecklists: [],
    loadingDefaultChecklists: false,
    openStandardChecklistModal: false,
    openDealerChecklistModal: false,
    errorMessage: "",
    popupVisible: false,
    popupMessage: "",
    popupType: "",
    editChecklist: false,
    currentChecklistID: 0,
    currentList: "",
    templatesListShort: [],
    brands: [],
    updateError: "",
    isLoading: false,
    checklistName: "",
    dealerQuestions: [],
    defaultQuestions: [],
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.DEALER_SELECTED) this.getDealerChecklists();

    if (nextProps.globalState.actionType === GLOBAL_ACTION_TYPES.LOCATION_SELECTED) this.getLocationChecklists();
  }

  componentDidMount() {
    this.handleRefresh();
  }

  handleRefresh = () => {
    this.getTags();
    this.getDefaultChecklists();
    this.getDealerChecklists();
    this.getLocationChecklists();
    this.getBrands();
    this.getChecklistTemplates();
    this.getDefaultQuestions();
    this.getDealerQuestions();
  };

  getTags = () => {
    Service.getTags()
      .then(result => {
        if (result && result.data && result.data.data && result.data.data.tags) {
          if (result && result.data && result.data.data && result.data.data.tags) {
            this.setState({ originalTags: result.data.data.tags });
          }
          const tags =
            result.data.data.tags && result.data.data.tags.length > 0
              ? result.data.data.tags.map((a, key) => {
                  return { text: a.name, value: a.id, key };
                })
              : [];
          this.setState({ tags });
        }
      })
      .catch(error => {
        console.error("Error getting tags: ", error);
        this.setState({ tags: [] });
      });
  };

  getDefaultChecklists = () => {
    this.setState({ loadingDefaultChecklists: true }, () => {
      Service.getDefaultChecklists()
        .then(result => {
          if (result && result.data && result.data.data) {
            let checklists = result.data.data.checklists;
            checklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            this.setState({
              loadingDefaultChecklists: false,
              defaultChecklists: checklists,
              originalDefaultChecklists: checklists,
            });
          } else {
            console.error("Error getting default checklists:");
            this.setState({ loadingDefaultChecklists: false, defaultChecklists: [] });
          }
        })
        .catch(error => {
          console.error("Error getting default checklists:", error);
          this.setState({ loadingDefaultChecklists: false, defaultChecklists: [] });
        });
    });
  };

  getDealerChecklists = () => {
    this.setState({ loadingDealerChecklists: true }, () => {
      Service.getDealer(this.props.globalState.selectedDealer.id)
        .then(result => {
          if (result && result.data && result.data.data) {
            let checklists = result.data.data.checklists;
            checklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            this.setState({
              loadingDealerChecklists: false,
              dealerChecklists: checklists,
              originalDealerChecklists: checklists,
            });
          } else {
            console.error("Error getting dealer:");
            this.setState({ loadingDealerChecklists: false, dealerChecklists: [], originalDealerChecklists: [] });
          }
        })
        .catch(error => {
          console.error("Error getting dealer:", error);
          this.setState({ loadingDealerChecklists: false, dealerChecklists: [], originalDealerChecklists: [] });
        });
    });
  };

  getLocationChecklists = () => {
    this.setState({ loadingLocationChecklists: true }, () => {
      Service.getLocationChecklists(this.props.globalState.selectedLocation.id)
        .then(result => {
          if (result && result.data && result.data.data && result.data.data.checklists) {
            let checklists = result.data.data.checklists;
            checklists.sort((a, b) => a.display_order - b.display_order);
            this.setState({
              loadingLocationChecklists: false,
              locationChecklists: checklists,
              originalLocationChecklists: checklists,
            });
          } else {
            console.error("Erorr getting location checklists");
            this.setState({
              loadingLocationChecklists: false,
              locationChecklists: [],
              originalLocationChecklists: [],
            });
          }
        })
        .catch(error => {
          console.error("Erorr getting location checklists: ", error);
          this.setState({
            loadingLocationChecklists: false,
            locationChecklists: [],
            originalLocationChecklists: [],
          });
        });
    });
  };

  getBrands = () => {
    Service.getBrands()
      .then(result => {
        if (result.data && result.data.data) {
          const brands =
            result.data.data.length > 0
              ? result.data.data.map((a, key) => {
                  return { text: a.name, value: a.id, key };
                })
              : [];
          this.setState({ brands });
        } else {
          console.error("error while fetching brands: ");
          this.setState({ brands: [] });
        }
      })
      .catch(error => {
        console.error("error while fetching brands: ", error);
        this.setState({ brands: [] });
      });
  };

  getChecklistTemplates = () => {
    Service.getTemplatesShort()
      .then(result => {
        if (result.data && result.data.data && result.data.data.checklist_templates) {
          const templatesListShort = result.data.data.checklist_templates.length > 0 ? result.data.data.checklist_templates : [];
          this.setState({ templatesListShort });
        } else {
          console.error("error while fetching templates list short: ");
          this.setState({ templatesListShort: [] });
        }
      })
      .catch(error => {
        console.error("error while fetching templates list short: ", error);
        this.setState({ templatesListShort: [] });
      });
  };

  getDealerQuestions = () => {
    Service.getQuestionsByDealer(this.props.globalState.selectedDealer.id)
      .then(result => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          let dealerQuestions = result.data.data.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0));
          this.setState({ dealerQuestions });
        }
      })
      .catch(error => {
        console.error("error while loading questions by dealer: ", error);
      });
  };

  getDefaultQuestions = () => {
    Service.getQuestions()
      .then(result => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          let defaultQuestions = result.data.data.sort((a, b) => (a.title > b.title ? 1 : a.title < b.title ? -1 : 0));
          this.setState({ defaultQuestions });
        }
      })
      .catch(error => {
        console.error("error while loading questions by dealer: ", error);
      });
  };

  handleDealerChange = () => {
    this.getDealerChecklists();
    this.getDealerQuestions();
  };

  handleLocationChange = () => {
    this.getLocationChecklists();
  };

  handleTagChange = (event, data) => {
    this.setState({ selectedTags: data.value }, () => {
      let { originalDefaultChecklists, originalDealerChecklists, originalLocationChecklists } = this.state;
      if (this.state.selectedTags.length > 0) {
        for (let i = 0; i < this.state.selectedTags.length; i++) {
          let currentTag = this.state.selectedTags[i];

          originalDefaultChecklists = originalDefaultChecklists.filter(item => this.computeFilters(currentTag, item.tags));
          originalDealerChecklists = originalDealerChecklists.filter(item => this.computeFilters(currentTag, item.tags));
          originalLocationChecklists = originalLocationChecklists.filter(item => this.computeFilters(currentTag, item.tags));
        }

        this.setState({
          defaultChecklists: originalDefaultChecklists,
          dealerChecklists: originalDealerChecklists,
          locationChecklists: originalLocationChecklists,
        });
      } else {
        this.setState({
          defaultChecklists: originalDefaultChecklists,
          dealerChecklists: originalDealerChecklists,
          locationChecklists: originalLocationChecklists,
        });
      }
    });
  };

  computeFilters = (currentTag, tags) => {
    if (tags) {
      for (let i = 0; i < tags.length; i++) {
        if (currentTag === tags[i].id) return true;
      }
    }

    return false;
  };

  handleNewChecklist = type => {
    if (type === "default") this.setState({ openStandardChecklistModal: true });
    else if (type === "dealer") this.setState({ openDealerChecklistModal: true });
  };

  handleNewStandardChecklist = () => {
    if (this.validateForm()) {
      this.setState({ isLoading: true }, () => {
        let requestData = { name: this.state.checklistName };

        Service.storeChecklist(requestData)
          .then(result => {
            let { defaultChecklists, originalDefaultChecklists } = this.state;
            let checklist = {
              active: false,
              dealer_id: null,
              name: this.state.checklistName,
              id: result.data.data.id,
            };
            originalDefaultChecklists.push(checklist);
            originalDefaultChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            if (originalDefaultChecklists.length !== defaultChecklists.length) {
              defaultChecklists.push(checklist);
              defaultChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            }

            this.setState(
              {
                defaultChecklists,
                originalDefaultChecklists,
                isLoading: false,
                openStandardChecklistModal: false,
                errorMessage: "",
                checklistName: "",
              },
              () => {
                this.handleEditChecklist(checklist.id, "default");
              }
            );
          })
          .catch(error => {
            console.error("Erorr while saving new checklist: ", error);
            let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
            this.props.setAlert({ type: "error", title: message });
            this.setState({ isLoading: false, openStandardChecklistModal: false });
          });
      });
    }
  };

  handleNewDealerChecklist = () => {
    if (this.validateForm()) {
      this.setState({ isLoading: true }, () => {
        let requestData = { name: this.state.checklistName, dealer_id: this.props.globalState.selectedDealer.id };

        Service.storeChecklist(requestData)
          .then(result => {
            let { dealerChecklists, originalDealerChecklists } = this.state;
            let checklist = {
              active: false,
              dealer_id: this.props.globalState.selectedDealer.id,
              name: this.state.checklistName,
              id: result.data.data.id,
            };
            originalDealerChecklists.push(checklist);
            originalDealerChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            if (originalDealerChecklists.length !== dealerChecklists.length) {
              dealerChecklists.push(checklist);
              dealerChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            }

            this.setState(
              {
                dealerChecklists,
                originalDealerChecklists,
                isLoading: false,
                openDealerChecklistModal: false,
                errorMessage: "",
                checklistName: "",
              },
              () => {
                this.handleEditChecklist(checklist.id, "dealer");
              }
            );
          })
          .catch(error => {
            console.error("Erorr while saving new checklist: ", error);
            let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
            this.props.setAlert({ type: "error", title: message });
            this.setState({ isLoading: false, openDealerChecklistModal: false });
          });
      });
    }
  };

  handleNewChildChecklist = (checklist, type) => {
    this.setState({ isLoading: true }, () => {
      if (type === "dealer") {
        let { dealerChecklists, originalDealerChecklists } = this.state;
        originalDealerChecklists.push(checklist);
        originalDealerChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        if (originalDealerChecklists.length !== dealerChecklists.length) {
          dealerChecklists.push(checklist);
          dealerChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        }

        this.setState(
          {
            dealerChecklists,
            originalDealerChecklists,
            isLoading: false,
            editChecklist: false,
            currentChecklistID: 0,
          },
          () => {
            this.setState({ editChecklist: true, currentChecklistID: checklist.id });
          }
        );
      } else if (type === "location") {
        let { locationChecklists, originalLocationChecklists } = this.state;
        originalLocationChecklists.push(checklist);
        originalLocationChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        if (originalLocationChecklists.length !== locationChecklists.length) {
          locationChecklists.push(checklist);
          locationChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
        }

        this.setState(
          {
            locationChecklists,
            originalLocationChecklists,
            isLoading: false,
            editChecklist: false,
            currentChecklistID: 0,
          },
          () => {
            this.setState({ editChecklist: true, currentChecklistID: checklist.id });
          }
        );
      }
    });
  };

  handleEditChecklist = (checklist_id, list) => {
    this.setState({ editChecklist: true, currentChecklistID: checklist_id, currentList: list });
  };

  handleUpdateChecklist = checklist => {
    if (!checklist.id) return;

    this.setState({ updateError: "", isLoading: true }, () => {
      Service.updateChecklist(checklist)
        .then(result => {
          const fullTags = this.state.originalTags.filter(tag => checklist.tags.includes(tag.id));
          const updatedChecklist = { ...checklist, tags: fullTags };

          let { defaultChecklists, dealerChecklists, locationChecklists, originalLocationChecklists, originalDealerChecklists, originalDefaultChecklists } = this.state;

          let i = defaultChecklists.findIndex(c => c.id === updatedChecklist.id);
          if (i !== -1) defaultChecklists[i] = updatedChecklist;

          i = dealerChecklists.findIndex(c => c.id === updatedChecklist.id);
          if (i !== -1) dealerChecklists[i] = updatedChecklist;

          i = locationChecklists.findIndex(c => c.id === updatedChecklist.id);
          if (i !== -1) locationChecklists[i] = { ...locationChecklists[i], ...updatedChecklist };

          i = originalLocationChecklists.findIndex(c => c.id === updatedChecklist.id);
          if (i !== -1) originalLocationChecklists[i] = { ...originalLocationChecklists[i], ...updatedChecklist };

          i = originalDealerChecklists.findIndex(c => c.id === updatedChecklist.id);
          if (i !== -1) originalDealerChecklists[i] = updatedChecklist;

          i = originalDefaultChecklists.findIndex(c => c.id === updatedChecklist.id);
          if (i !== -1) originalDefaultChecklists[i] = updatedChecklist;

          this.setState({
            isLoading: false,
            editChecklist: false,
            currentChecklistID: 0,
            defaultChecklists,
            dealerChecklists,
            locationChecklists,
            originalLocationChecklists,
            originalDealerChecklists,
            originalDefaultChecklists,
          });
        })
        .catch(error => {
          console.error("error while updating checklists: ", error);
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.setState({ isLoading: false, updateError: message });
        });
    });
  };

  handleDiscardChecklist = () => {
    this.setState({ editChecklist: false, currentChecklistID: 0 });
  };

  validateForm = () => {
    let { checklistName } = this.state;

    let errorMessage = "";
    if (!checklistName.trim()) {
      errorMessage = (
        <Message negative>
          <p>{this.props.t("this_field_is_required").message || "This field is required"}</p>
        </Message>
      );
    }

    this.setState({ errorMessage });
    return errorMessage.length === 0;
  };

  handleChecklistNameChange = event => {
    this.setState({ checklistName: event.target.value });
  };

  handleReorder = (currentOrder, newOrder) => {
    let updatedChecklists = this.state.locationChecklists
      .map(item => {
        if (item.display_order === currentOrder) return { ...item, display_order: newOrder };
        if (item.display_order === newOrder) return { ...item, display_order: currentOrder };
        return item;
      })
      .sort((a, b) => a.display_order - b.display_order);

    this.setState({ locationChecklists: updatedChecklists }, async () => {
      const checklistToMove = updatedChecklists.find(item => item.display_order === newOrder);
      if (checklistToMove) await Service.reorder(checklistToMove, this.props.globalState.selectedLocation.id);
    });
  };

  handleDetachLocation = checklist_id => {
    if (!checklist_id) return;

    this.setState({ updateError: "", isLoading: true }, () => {
      Service.locationDetach(checklist_id, this.props.globalState.selectedLocation.id)
        .then(result => {
          let locationChecklists = [...this.state.locationChecklists];
          let originalLocationChecklists = [...this.state.originalLocationChecklists];

          const checklistDisplayOrder = locationChecklists.find(item => item.id === checklist_id)?.display_order;
          locationChecklists = locationChecklists
            .filter(item => item.display_order !== checklistDisplayOrder)
            .map(item => (item.display_order > checklistDisplayOrder ? { ...item, display_order: item.display_order - 1 } : item));
          originalLocationChecklists = originalLocationChecklists
            .filter(item => item.display_order !== checklistDisplayOrder)
            .map(item => (item.display_order > checklistDisplayOrder ? { ...item, display_order: item.display_order - 1 } : item));

          this.setState({
            isLoading: false,
            editChecklist: false,
            currentChecklistID: 0,
            locationChecklists,
            originalLocationChecklists,
          });
        })
        .catch(error => {
          console.error("error while detaching checklist from location: ", error);
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.setState({ isLoading: false, updateError: message });
        });
    });
  };

  handleDetachDealer = checklist_id => {
    if (!checklist_id) return;

    this.setState({ updateError: "", isLoading: true }, () => {
      Service.dealerDetach(checklist_id, this.props.globalState.selectedDealer.id)
        .then(result => {
          let { dealerChecklists, originalDealerChecklists } = this.state;

          let i = dealerChecklists.findIndex(c => c.id === checklist_id);
          if (i !== -1) dealerChecklists.splice(i, 1);

          i = originalDealerChecklists.findIndex(c => c.id === checklist_id);
          if (i !== -1) originalDealerChecklists.splice(i, 1);

          this.setState({
            isLoading: false,
            editChecklist: false,
            currentChecklistID: 0,
            dealerChecklists,
            originalDealerChecklists,
          });
        })
        .catch(error => {
          console.error("error while detaching checklist from dealer: ", error);
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.setState({ isLoading: false, updateError: message });
        });
    });
  };

  handleRemoveChecklist = checklist_id => {
    if (!checklist_id) return;

    this.setState({ updateError: "", isLoading: true }, () => {
      Service.deleteChecklist(checklist_id)
        .then(result => {
          let { defaultChecklists, dealerChecklists, originalDealerChecklists, originalDefaultChecklists } = this.state;

          let i = defaultChecklists.findIndex(c => c.id === checklist_id);
          if (i !== -1) defaultChecklists.splice(i, 1);

          i = dealerChecklists.findIndex(c => c.id === checklist_id);
          if (i !== -1) dealerChecklists.splice(i, 1);

          i = originalDealerChecklists.findIndex(c => c.id === checklist_id);
          if (i !== -1) originalDealerChecklists.splice(i, 1);

          i = originalDefaultChecklists.findIndex(c => c.id === checklist_id);
          if (i !== -1) originalDefaultChecklists.splice(i, 1);

          this.setState({
            isLoading: false,
            editChecklist: false,
            currentChecklistID: 0,
            defaultChecklists,
            dealerChecklists,
            originalDealerChecklists,
            originalDefaultChecklists,
          });
        })
        .catch(error => {
          console.error("error while deleting checklist: ", error);
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.setState({ isLoading: false, updateError: message });
        });
    });
  };

  onDragStart = (ev, id, origin) => {
    ev.dataTransfer.setData("id", id);
    ev.dataTransfer.setData("origin", origin);
  };

  onDragOver = ev => {
    ev.stopPropagation();
    ev.preventDefault();
  };

  onDragEnter = (ev, index, list) => {
    let { locationChecklists, dealerChecklists } = this.state;

    if (list === "location") locationChecklists[index].hover = true;
    else if (list === "dealer") dealerChecklists[index].hover = true;

    this.setState({ locationChecklists, dealerChecklists });
  };

  onDragLeave = (ev, index, list) => {
    let { locationChecklists, dealerChecklists } = this.state;

    if (list === "location") locationChecklists[index].hover = false;
    else if (list === "dealer") dealerChecklists[index].hover = false;

    this.setState({ locationChecklists, dealerChecklists });
  };

  onDrop = (ev, destination) => {
    let origin = ev.dataTransfer.getData("origin");
    if (origin === destination) return;

    let id = parseInt(ev.dataTransfer.getData("id"), 10);
    let { defaultChecklists, dealerChecklists, originalDealerChecklists } = this.state;
    let locationChecklists = [...this.state.locationChecklists];
    let originalLocationChecklists = [...this.state.originalLocationChecklists];
    let list;

    if (origin === "dealer") list = dealerChecklists;
    else if (origin === "default") list = defaultChecklists;

    if (destination === "location") {
      Service.locationAttach(id, this.props.globalState.selectedLocation.id)
        .then(result => {
          let i = list.findIndex(c => c.id === id);
          if (i !== -1) {
            let checklist = list[i];
            checklist.display_order = 1;

            locationChecklists = locationChecklists.map(item => ({ ...item, display_order: item.display_order + 1 }));
            originalLocationChecklists = originalLocationChecklists.map(item => ({ ...item, display_order: item.display_order + 1 }));

            this.setState({ locationChecklists: [checklist, ...locationChecklists], originalLocationChecklists: [checklist, ...originalLocationChecklists] });
          }
        })
        .catch(error => {
          console.error("error while attaching to location: ", error);
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.props.setAlert({ type: "error", title: message });
        });
    } else if (destination === "dealer") {
      Service.dealerAttach(id, this.props.globalState.selectedDealer.id)
        .then(result => {
          let i = list.findIndex(c => c.id === id);
          if (i !== -1) {
            let checklist = list[i];
            originalDealerChecklists.push(checklist);
            originalDealerChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            if (originalDealerChecklists.length !== dealerChecklists.length) {
              dealerChecklists.push(checklist);
              dealerChecklists.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
            }
          }

          this.setState({ dealerChecklists, originalDealerChecklists });
        })
        .catch(error => {
          console.error("error while attaching to dealer: ", error);
          let message = error.response.data && error.response.data.errors && error.response.data.errors.length > 0 ? error.response.data.errors[0] : error.message;
          this.props.setAlert({ type: "error", title: message });
        });
    }
  };

  displayLoading = () => {
    return (
      <div className="Table__loading Loader-Placeholder">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <section>{this.props.t("loading_checklists").message || "Loading checklists"}</section>
      </div>
    );
  };

  render() {
    const {
      currentChecklistID,
      currentList,
      tags,
      brands,
      templatesListShort,
      selectedTags,
      editChecklist,
      locationChecklists,
      dealerChecklists,
      defaultChecklists,
      loadingLocationChecklists,
      loadingDealerChecklists,
      loadingDefaultChecklists,
      openDealerChecklistModal,
      openStandardChecklistModal,
      errorMessage,
      updateError,
      isLoading,
      defaultQuestions,
      dealerQuestions,
    } = this.state;
    let t = this.props.t;

    let locationLists =
      locationChecklists && locationChecklists.length > 0
        ? locationChecklists.map((item, index) => {
            return (
              <List.Item
                className={`ChecklistItem ${item.active ? "green" : "red"} ${item.hover ? "hover" : ""}`}
                key={item.id}
                onDragEnter={e => this.onDragEnter(e, index, "location")}
                onDragLeave={e => this.onDragLeave(e, index, "location")}
              >
                <List.Icon name={item.dealer_id ? "warehouse" : "globe"} size="large" verticalAlign="middle" />
                <List.Content>
                  <span className={`-cursor-pointer-no-color`} onClick={() => this.handleEditChecklist(item.id, "location")}>
                    {item.name}
                  </span>
                  {item.tags &&
                    item.tags.length > 0 &&
                    item.tags.map((tag, index2) => {
                      return (
                        <span className="checklist_tag" key={index2 + "checklist" + item.id + "tag" + tag.id} style={{ backgroundColor: tag.color }}>
                          {tag.name}
                        </span>
                      );
                    })}
                  <Button.Group floated="right">
                    {item.display_order < locationChecklists.length && (
                      <Button icon="angle down" onClick={() => this.handleReorder(item.display_order, item.display_order + 1)} />
                    )}
                    {item.display_order !== 1 && <Button icon="angle up" onClick={() => this.handleReorder(item.display_order, item.display_order - 1)} />}
                  </Button.Group>
                </List.Content>
              </List.Item>
            );
          })
        : [];

    let dealerLists =
      dealerChecklists && dealerChecklists.length > 0
        ? dealerChecklists.map((item, index) => {
            return (
              <List.Item
                className={`ChecklistItem ${item.active ? "green" : "red"} ${item.hover ? "hover" : ""} -cursor-pointer-no-color`}
                key={item.id}
                draggable
                onDragStart={e => this.onDragStart(e, item.id, "dealer")}
                onDragEnter={e => this.onDragEnter(e, index, "dealer")}
                onDragLeave={e => this.onDragLeave(e, index, "dealer")}
                onClick={() => this.handleEditChecklist(item.id, "dealer")}
              >
                <List.Icon name={item.dealer_id ? "warehouse" : "globe"} size="large" verticalAlign="middle" />
                <List.Content>
                  <span>{item.name}</span>
                  {item.tags &&
                    item.tags.length > 0 &&
                    item.tags.map((tag, index2) => {
                      return (
                        <span className="checklist_tag" key={index2 + "checklist" + item.id + "tag" + tag.id} style={{ backgroundColor: tag.color }}>
                          {tag.name}
                        </span>
                      );
                    })}
                </List.Content>
              </List.Item>
            );
          })
        : [];

    let defaultLists =
      defaultChecklists && defaultChecklists.length > 0
        ? defaultChecklists.map(item => {
            return (
              <List.Item
                className={`ChecklistItem ${item.active ? "green" : "red"} -cursor-pointer-no-color`}
                key={item.id}
                draggable
                onDragStart={e => this.onDragStart(e, item.id, "default")}
                onClick={() => this.handleEditChecklist(item.id, "default")}
              >
                <List.Icon name={item.dealer_id ? "warehouse" : "globe"} size="large" verticalAlign="middle" />
                <List.Content>
                  <span>{item.name}</span>
                  {item.tags &&
                    item.tags.length > 0 &&
                    item.tags.map((tag, index2) => {
                      return (
                        <span className="checklist_tag" key={index2 + "checklist" + item.id + "tag" + tag.id} style={{ backgroundColor: tag.color }}>
                          {tag.name}
                        </span>
                      );
                    })}
                </List.Content>
              </List.Item>
            );
          })
        : [];

    return (
      <div className="Checklist">
        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>

        {editChecklist && (
          <EditChecklist
            selectedLocationID={this.props.globalState.selectedLocation.id}
            selectedDealerID={this.props.globalState.selectedLocation.dealer_id}
            handleNewChildChecklist={this.handleNewChildChecklist}
            handleUpdateChecklist={this.handleUpdateChecklist}
            handleDiscardChecklist={this.handleDiscardChecklist}
            handleRemoveChecklist={this.handleRemoveChecklist}
            handleDetachDealer={this.handleDetachDealer}
            handleDetachLocation={this.handleDetachLocation}
            checklistID={currentChecklistID}
            currentList={currentList}
            tags={tags}
            originalTags={this.state.originalTags}
            templatesListShort={templatesListShort}
            brands={brands}
            updateError={updateError}
            loading={isLoading}
            defaultQuestions={defaultQuestions}
            dealerQuestions={dealerQuestions}
            authUser={this.props.authState.user}
          />
        )}
        <Modal open={openDealerChecklistModal} closeOnDimmerClick={false} size="mini">
          <Modal.Header>Add new dealer checklist</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <UserInput
                style={{ width: "100%" }}
                placeholder={this.props.t("checklist_name").message || "Checklist name"}
                defaultValue={this.state.checklistName}
                onChange={this.handleChecklistNameChange}
              />
              {errorMessage}
            </Modal.Description>
            <Modal.Actions style={{ marginTop: "10px" }}>
              <Button color="green" floated="right" onClick={this.handleNewDealerChecklist} loading={isLoading} disabled={isLoading}>
                <Icon name="checkmark" />
                {t("confirm").message || "CONFIRM"}
              </Button>
              <Button
                color="red"
                onClick={() => {
                  this.setState({ openDealerChecklistModal: false, checklistName: "", errorMessage: "" });
                }}
              >
                <Icon name="close" />
                {t("cancel").message || "CANCEL"}
              </Button>
            </Modal.Actions>
          </Modal.Content>
        </Modal>

        <Modal open={openStandardChecklistModal} closeOnDimmerClick={false} size="mini">
          <Modal.Header>Add new standard checklist</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <UserInput
                style={{ width: "100%" }}
                placeholder={this.props.t("checklist_name").message || "Checklist name"}
                defaultValue={this.state.checklistName}
                onChange={this.handleChecklistNameChange}
              />
              {errorMessage}
            </Modal.Description>
            <Modal.Actions style={{ marginTop: "10px" }}>
              <Button color="green" floated="right" onClick={this.handleNewStandardChecklist} loading={isLoading} disabled={isLoading}>
                <Icon name="checkmark" />
                {t("confirm").message || "CONFIRM"}
              </Button>
              <Button
                color="red"
                onClick={() => {
                  this.setState({ openStandardChecklistModal: false, checklistName: "", errorMessage: "" });
                }}
              >
                <Icon name="close" />
                {t("cancel").message || "CANCEL"}
              </Button>
            </Modal.Actions>
          </Modal.Content>
        </Modal>

        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -no-padding -no-margin">
            <Grid.Row>
              <Grid.Column width={16}>
                <Dropdown
                  placeholder={this.props.t("filter_by_tag").message || "Filter by tag"}
                  selection
                  fluid
                  search
                  options={tags}
                  multiple={true}
                  value={selectedTags}
                  onChange={this.handleTagChange}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </SubHeader>

        <Grid columns={3} divided className="-container-large" style={{ padding: "0 2rem" }}>
          <Grid.Row className="-no-margin -no-padding">
            <Grid.Column width="6" className="-no-padding-left">
              <h4 style={{ margin: "5px 0" }}>Location Checklists</h4>
              <List className="Scrollbar" onDrop={e => this.onDrop(e, "location")} onDragOver={e => this.onDragOver(e)}>
                {loadingLocationChecklists && this.displayLoading()}
                {!loadingLocationChecklists && locationLists}
              </List>
            </Grid.Column>

            <Grid.Column width="5">
              <h4 style={{ margin: "5px 0" }}>Dealer Checklists</h4>
              <List className="Scrollbar" onDrop={e => this.onDrop(e, "dealer")} onDragOver={e => this.onDragOver(e)}>
                {loadingDealerChecklists && this.displayLoading()}
                {!loadingDealerChecklists && dealerLists}
              </List>
            </Grid.Column>

            <Grid.Column width="5">
              <h4 style={{ margin: "5px 0" }}>Default Checklists</h4>
              <List className="Scrollbar">
                {loadingDefaultChecklists && this.displayLoading()}
                {!loadingDefaultChecklists && defaultLists}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <FloatingActions
          items={[
            { id: "default", icon: "globe", label: "Default Checklist" },
            {
              id: "dealer",
              icon: "warehouse",
              label: "Dealer Checklist",
            },
          ]}
          onRootClick={this.handleNewChecklist}
        />
      </div>
    );
  }
}

export default withTranslation()(Checklists);
