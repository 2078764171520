import Service from "./service";
import AppointmentsService from "./../Appointments/service";

import { ROLES } from "../Users/roles";
import { CAR_NOTE_TYPES } from "../../util/common";

const SORT_NEWEST_FIRST = (a, b) => {
  return b.date > a.date ? 1 : -1;
};

export const CARS_ACTION_TYPES = {
  DESELECT_CAR_SUCCESS: "cars/DESELECT_CAR_SUCCESS",
  GET_CAR: "cars/GET_CAR",
  GET_CAR_FAIL: "cars/GET_CAR_FAIL",
  GET_CAR_NOT_AUTHORIZED: "cars/GET_CAR_NOT_AUTHORIZED",
  GET_CAR_SUCCESS: "cars/GET_CAR_SUCCESS",
  GET_CAR_APPOINTMENTS: "cars/GET_CAR_APPOINTMENTS",
  GET_CAR_APPOINTMENTS_FAIL: "cars/GET_CAR_APPOINTMENTS_FAIL",
  GET_CAR_APPOINTMENTS_SUCCESS: "cars/GET_CAR_APPOINTMENTS_SUCCESS",
  GET_CAR_SNOOZED_ITEMS_SUCCESS: "cars/GET_CAR_SNOOZED_ITEMS_SUCCESS",
  GET_CAR_SNOOZED_ITEMS_FAIL: "cars/GET_CAR_SNOOZED_ITEMS_FAIL",
  WEB_SOCKET_UPDATE: "cars/WEB_SOCKET_UPDATE",
  NO_UPDATE_TO_APPLY: "cars/NO_UPDATE_TO_APPLY",
  CAR_UPDATE_STARTED: "cars/CAR_UPDATE_STARTED",
  CAR_UPDATE_APPLIED: "cars/CAR_UPDATE_APPLIED",
};

export function getCar(carID) {
  return (dispatch, getState) => {
    let car = null;
    let sortedAppointments = null;
    let snoozedQuestions = null;
    let newestCustomer = null;
    let errorMessagesPromised = null;

    // Get the car info
    let fetchCar = Service.getCar(carID)
      .then(response => {
        car = response.data ? response.data.data : null;
      })
      .catch(error => {
        errorMessagesPromised = error.response && error.response.data ? error.response.data.error : error;
        if (error.response?.data?.errors[0]?.includes("authorized")) dispatch(getCarFailUnauthorized(errorMessagesPromised));
        else dispatch(getCarFail(errorMessagesPromised));
      });

    // Get appointments of this and sort them so the newest appointment is first in
    // the array
    let fetchAppointments = Service.getCarAppointments(carID)
      .then(response => {
        if (response.data && response.data.data.appointments && response.data.data.appointments.length > 0) {
          sortedAppointments = response.data.data.appointments.sort(SORT_NEWEST_FIRST);
        }
      })
      .catch(error => {
        errorMessagesPromised = error.response && error.response.data ? error.response.data.error : error;
        if (error.response?.data?.errors[0]?.includes("authorized")) dispatch(getCarFailUnauthorized(errorMessagesPromised));
        else dispatch(getCarFail(errorMessagesPromised));
      });

    // Get snoozed questions for this car
    let fetchSnoozedQuestions = AppointmentsService.getCarSnoozedItems({ car_id: carID })
      .then(response => {
        snoozedQuestions = [];

        if (Array.isArray(response.data?.data)) {
          snoozedQuestions = response.data.data.sort((a, b) => {
            return a.snooze_date > b.snooze_date ? 1 : -1;
          });
        }
      })
      .catch(error => {
        errorMessagesPromised = error.response && error.response.data ? error.response.data.error : error;
        if (error.response?.data?.errors[0]?.includes("authorized")) dispatch(getCarFailUnauthorized(errorMessagesPromised));
        else dispatch(getCarFail(errorMessagesPromised));
      });

    let PromisesToResolve = [fetchCar, fetchAppointments, fetchSnoozedQuestions];

    Promise.all(PromisesToResolve)
      .then(() => {
        if (!sortedAppointments || sortedAppointments.length === 0 || getState().auth.user.role_id === ROLES.MANUFACTURER) {
          return dispatch(getCarSuccess(car, {}, sortedAppointments, snoozedQuestions));
        }

        // Get the newest appointment detail and extract the customer info from it
        AppointmentsService.getAppointment(sortedAppointments[0].id)
          .then(response => {
            let newestAppointment = response.data.data;
            newestCustomer = {
              owner: newestAppointment.customer_owner,
              owner_id: newestAppointment.customer_owner_id,
              driver: newestAppointment.customer_driver,
              driver_id: newestAppointment.customer_driver_id,
              contract: newestAppointment.customer_contract,
              contract_id: newestAppointment.customer_contract_id,
            };

            return dispatch(getCarSuccess(car, newestCustomer, sortedAppointments, snoozedQuestions));
          })
          .catch(error => {
            errorMessagesPromised = error.response && error.response.data ? error.response.data.error : error;
            if (error.response?.data?.errors[0]?.includes("authorized")) dispatch(getCarFailUnauthorized(errorMessagesPromised));
            else dispatch(getCarFail(errorMessagesPromised));
          });
      })
      .catch(error => {
        let errorMessages = error.response && error.response.data ? error.response.data.error : error;
        if (error.response?.data?.errors[0]?.includes("authorized")) dispatch(getCarFailUnauthorized(errorMessages));
        else dispatch(getCarFail(errorMessages));
      });
  };
}

export function getCarSuccess(car, newestCustomer, sortedAppointments, snoozedQuestions) {
  return dispatch =>
    dispatch({ type: CARS_ACTION_TYPES.GET_CAR_SUCCESS, car, customer: newestCustomer, carAppointments: sortedAppointments, carSnoozedQuestions: snoozedQuestions });
}

export function getCarFailUnauthorized(errorMessages) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED, errorMessages });
}

export function getCarFail(errorMessages) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.GET_CAR_FAIL, errorMessages });
}

export function getCarAppointments(carID) {
  return dispatch => {
    Service.getCarAppointments(carID)
      .then(response => {
        dispatch(getAppointmentsSuccess(response.data.data.appointments));
      })
      .catch(error => {
        dispatch(getAppointmentsFail(error.response.data.error));
      });
  };
}

export function getAppointmentsSuccess(carAppointments) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.GET_CAR_APPOINTMENTS_SUCCESS, carAppointments });
}

export function getAppointmentsFail(errorMessages) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.GET_CAR_APPOINTMENTS_FAIL, errorMessages });
}

export function deselectCar() {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.DESELECT_CAR_SUCCESS });
}

export function getSnoozedItems(carID) {
  return dispatch => {
    Service.getCarSnoozedItems(carID)
      .then(response => {
        dispatch(getSnoozedItemsSuccess(response.data.data.appointments));
      })
      .catch(error => {
        dispatch(getSnoozedItemsFail(error.response.data.error));
      });
  };
}

export function getSnoozedItemsSuccess(carSnoozedItems) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.GET_CAR_SNOOZED_ITEMS_SUCCESS, carSnoozedItems });
}

export function getSnoozedItemsFail(errorMessages) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.GET_CAR_SNOOZED_ITEMS_FAIL, errorMessages });
}

export function addCarAttachment(attachmentNote) {
  return (dispatch, getState) => {
    dispatch({ type: CARS_ACTION_TYPES.CAR_UPDATE_STARTED });

    const selectedCar = getState().cars.selectedCar;
    const notes = [...(selectedCar.notes || [])];
    const carNoteIdx = notes.findIndex(n => n.car_note_type_id === CAR_NOTE_TYPES.DEFAULT);

    dispatch({
      type: CARS_ACTION_TYPES.CAR_UPDATE_APPLIED,
      selectedCar: {
        ...selectedCar,
        notes: carNoteIdx > -1 ? notes.map((note, idx) => (idx === carNoteIdx ? attachmentNote : note)) : [...notes, attachmentNote],
      },
    });
  };
}

export function deleteCarAttachment(url) {
  return (dispatch, getState) => {
    dispatch({ type: CARS_ACTION_TYPES.CAR_UPDATE_STARTED });

    const selectedCar = getState().cars.selectedCar;
    const notes = [...(selectedCar.notes || [])];
    const carNoteIdx = notes.findIndex(n => n.car_note_type_id === CAR_NOTE_TYPES.DEFAULT);

    if (carNoteIdx > -1) {
      dispatch({
        type: CARS_ACTION_TYPES.CAR_UPDATE_APPLIED,
        selectedCar: {
          ...selectedCar,
          notes: notes.map((note, idx) => (idx === carNoteIdx ? { ...note, attachments: (note.attachments || []).filter(attachment => attachment.url !== url) } : note)),
        },
      });
    }
  };
}
export function updateSelectedCar(update) {
  return (dispatch, getState) => {
    dispatch({ type: CARS_ACTION_TYPES.CAR_UPDATE_STARTED });

    const selectedCar = { ...getState().cars.selectedCar, ...update };

    dispatch({ type: CARS_ACTION_TYPES.CAR_UPDATE_APPLIED, selectedCar });
  };
}

export function websocketCarUpdate(webSocketUpdate) {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.WEB_SOCKET_UPDATE, webSocketUpdate });
}

export function applyWebsocketUpdate(payload) {
  const { _topic, body } = payload;
  const { update, car_id } = body;

  return (dispatch, getState) => {
    if (!update) {
      dispatch(noUpdateToApply());
      return;
    }

    const selectedCar = getState().cars.selectedCar;
    const isThisOneSelected = selectedCar?.id === car_id;

    if (!isThisOneSelected) {
      dispatch(noUpdateToApply());
      return;
    }

    switch (_topic) {
      case "CarAttachmentAddedMessage":
        return dispatch(addCarAttachment(update));

      case "CarAttachmentRemovedMessage":
        return dispatch(deleteCarAttachment(update.url));

      case "CarUpdateMessage":
        return dispatch(updateSelectedCar(update));

      default:
        return dispatch(noUpdateToApply());
    }
  };
}

export function noUpdateToApply() {
  return dispatch => dispatch({ type: CARS_ACTION_TYPES.NO_UPDATE_TO_APPLY });
}

const INITIAL_STATE = {
  actionType: "",
  carAppointments: null,
  carSnoozedItems: null,
  errorMessages: {},
  isLoading: false,
  selectedCar: null,
  selectedCarAppointments: null,
  selectedCarSnoozedQuestions: null,
  selectedCustomer: null,
  webSocketUpdate: null,
};

const carsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CARS_ACTION_TYPES.DESELECT_CAR_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        selectedCar: null,
        selectedCarAppointments: null,
        selectedCustomer: null,
      };
    case CARS_ACTION_TYPES.GET_CAR:
      return {
        ...state,
        actionType: action.type,
        isLoading: true,
      };
    case CARS_ACTION_TYPES.GET_CAR_FAIL:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        errorMessages: action.errorMessages,
      };
    case CARS_ACTION_TYPES.GET_CAR_NOT_AUTHORIZED:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        errorMessages: action.errorMessages,
      };
    case CARS_ACTION_TYPES.GET_CAR_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        selectedCar: action.car,
        selectedCustomer: action.customer,
        selectedCarAppointments: action.carAppointments,
        selectedCarSnoozedQuestions: action.carSnoozedQuestions,
      };
    case CARS_ACTION_TYPES.GET_CAR_APPOINTMENTS:
      return {
        ...state,
        actionType: action.type,
        isLoading: true,
      };
    case CARS_ACTION_TYPES.GET_CAR_APPOINTMENTS_FAIL:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        errorMessages: action.errorMessages,
      };
    case CARS_ACTION_TYPES.GET_CAR_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        carAppointments: action.carAppointments,
      };
    case CARS_ACTION_TYPES.GET_CAR_SNOOZED_ITEMS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        carSnoozedItems: action.carSnoozedItems,
      };
    case CARS_ACTION_TYPES.GET_CAR_SNOOZED_ITEMS_FAIL:
      return {
        ...state,
        actionType: action.type,
        isLoading: false,
        errorMessages: action.errorMessages,
      };
    case CARS_ACTION_TYPES.WEB_SOCKET_UPDATE:
      return {
        ...state,
        actionType: action.type,
        webSocketUpdate: action.webSocketUpdate,
      };
    case CARS_ACTION_TYPES.NO_UPDATE_TO_APPLY:
      return {
        ...state,
        actionType: action.type,
      };

    case CARS_ACTION_TYPES.CAR_UPDATE_STARTED:
      return {
        ...state,
        actionType: action.type,
      };

    case CARS_ACTION_TYPES.CAR_UPDATE_APPLIED:
      return {
        ...state,
        actionType: action.type,
        selectedCar: action.selectedCar,
      };

    case "persist/REHYDRATE":
      var incoming = action.payload;
      if (incoming && incoming.cars) {
        return {
          ...state,
          ...incoming.cars,
          actionType: state.actionType,
          errorMessages: state.errorMessages,
          selectedCar: state.selectedCar,
          selectedCarAppointments: state.selectedCarAppointments,
          selectedCarSnoozedQuestions: state.selectedCarSnoozedQuestions,
          selectedCustomer: state.selectedCustomer,
        };
      }
      return state;
    default:
      return state;
  }
};

export default carsReducer;
