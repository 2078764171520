import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Button, Icon, Grid, Search, Dropdown, Modal, Checkbox } from "semantic-ui-react";
import _ from "lodash";

import { isAdmin } from "../../util/common";
import { setAlert, updateLocation as globalLocationUpdate, updateDealer as globalDealerUpdate, getDealersSuccess } from "../App/store";
import { SearchPortal, SubHeader, UserMenuActionsPortal, FloatingActions, LocationDetail, LocationCreate, CustomConfirm } from "./../../components";
import DealersTable from "./DealersTable";
import DealerDetail from "./DealerDetail";
import Can from "../Can";

import AppService from "../App/service";
import Service from "./service";
import { ROLES } from "../Users/roles";

import "./Dealers.css";

const filterDealersBySearchTerm = (dealers, searchTerm) => {
  const term = searchTerm.toLowerCase();
  return searchTerm
    ? dealers.filter(
        d =>
          d.dealerName.toLowerCase().includes(term) ||
          (d.info.name && d.info.name.toLowerCase().includes(term)) ||
          (d.info.email && d.info.email.toLowerCase().includes(term))
      )
    : dealers;
};

/*
    Dealers Admin
*/
class AdminDealers extends Component {
  state = {
    loadingDealers: false,
    dealers: [],
    originalDealers: [],
    searchTerm: "",
    selectedStatus: null,
    areLocationsOpen: false,
    selectedDealer: {},
    selectedLocation: null,
    submittingLocation: false,
    brands: [],
    languages: [],
    isDealerDetailVisible: false,
    isDeleteModalOpen: false,
    isCreateLocationVisible: false,
    isSubmittingDetail: false,
    isSubmittingNewLocation: false,
    detailViewMode: "",
    generatedSvcKey: null,
    isDeletingLocation: false,
    deleteLocationError: null,
    didSubmitLocation: false,
    selectedDealerId: null,
  };

  componentDidMount() {
    this.getDealers();
    this.getBrands();
    this.getLanguages();
  }

  deleteDealer = dealerToDelete => {
    const { originalDealers, dealers } = this.state;
    const helpArr = originalDealers.filter(dealer => {
      return dealer.all_info.id !== dealerToDelete;
    });
    const helpArr2 = dealers.filter(dealer => {
      return dealer.all_info.id !== dealerToDelete;
    });
    this.setState({
      originalDealers: [...helpArr],
      dealers: [...helpArr2],
    });
  };

  getDealers = () => {
    this.setState(
      {
        loadingDealers: true,
      },
      () => {
        AppService.getDealers()
          .then(response => {
            const dealers =
              response && response.data && response.data.data && response.data.data.dealers
                ? response.data.data.dealers.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                  })
                : [];

            let dealerLocations = [];

            // Because of CLA-909
            dealers.map(dealer => dealerLocations.push(this.formatDealer(dealer)));
            this.props.getDealersSuccess(dealers);

            this.setState(
              {
                loadingDealers: false,
                dealers: dealerLocations,
                originalDealers: dealerLocations,
              },
              () => this.filterDealers()
            );
          })
          .catch(err => {
            this.props.setAlert({
              type: "error",
              title: err.message,
            });
            console.error("Error getting dealers.", err);
            this.setState({ loadingDealers: false });
          });
      }
    );
  };

  getBrands = () => {
    Service.getBrands()
      .then(response => {
        const brands = response && response.data && response.data.data ? response.data.data : [];
        this.setState({
          brands,
        });
      })
      .catch(err => {
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        console.error("Error getting brands.", err);
        this.setState({ brands: [] });
      });
  };

  getLanguages = () => {
    Service.getLanguages()
      .then(response => {
        const languages = response?.data?.data ? response.data.data : [];
        this.setState({
          languages,
        });
      })
      .catch(err => {
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        console.error("Error getting languages.", err);
        this.setState({ languages: [] });
      });
  };

  getDealer = id => {
    Service.getDealerDetail({ id })
      .then(response => {
        const dealer = response && response.data && response.data.data ? response.data.data : null;
        let selectedDealer = this.formatDealer(dealer);
        this.setState({
          detailViewMode: "edit",
          isDealerDetailVisible: true,
          selectedDealer,
        });
      })
      .catch(err => {
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        console.error("Error getting dealers.", err);
        this.setState({ isDealerDetailVisible: false });
      });
  };

  getLocation = id => {
    AppService.getLocation({ id })
      .then(response => {
        const selectedLocation = response && response.data && response.data.data ? response.data.data : null;
        selectedLocation.brand_ids = selectedLocation.brands ? selectedLocation.brands.map(b => b.id) : [];

        this.setState({ selectedLocation });
      })
      .catch(err => {
        this.props.setAlert({
          type: "error",
          title: err.message,
        });
        console.error("Error getting location.", err);
        this.setState({ selectedLocation: null });
      });
  };

  filterDealers = () => {
    let dealers = [...this.state.originalDealers];
    let selectedStatus = this.state.selectedStatus;

    selectedStatus = selectedStatus === "showAll" ? null : selectedStatus;

    if (selectedStatus !== null) {
      dealers = dealers.filter(u => u.dealerActive === selectedStatus);
    }
    dealers = filterDealersBySearchTerm(dealers, this.state.searchTerm);

    this.setState({ dealers });
  };

  toggleLocationsOpen = () => {
    this.setState({ areLocationsOpen: !this.state.areLocationsOpen });
  };

  handleGenerateSvcKey = dealer_id => {
    if (dealer_id) {
      this.setState(
        {
          isSubmittingDetail: true,
        },
        () => {
          Service.createSvcAccRequest({ dealer_id })
            .then(result => {
              if (result && result.data && result.data.token) {
                this.setState({
                  isSubmittingDetail: false,
                  generatedSvcKey: result.data.token,
                });
              }
            })
            .catch(error => {
              this.props.setAlert({
                type: "error",
                title: error.message,
              });
              console.error("Error on generating svc key.", error);
              this.setState({ isSubmittingDetail: false });
            });
        }
      );
    }
  };

  handleLocationCreate = location => {
    if (!location || !location.language_code || !location.name || !location.country || !location.commercial_name) {
      return;
    }
    location.dealer_id = this.state.selectedDealerId;
    if (!location.photo) {
      this.createLocation(location);
      return;
    }

    let fd = new FormData();
    fd.append("file", location.photo);
    Service.uploadLogo(fd)
      .then(result => {
        if (result && result.data && result.data.data) {
          location.logo = result.data.data.URL;
        }
        this.createLocation(location);
      })
      .catch(error => {
        console.error("Error saving location.", error);
      });
  };

  createLocation = loc => {
    this.setState({ isSubmittingNewLocation: true }, () => {
      Service.createLocation(loc)
        .then(res => {
          this.setState({ isSubmittingNewLocation: false, isCreateLocationVisible: false, selectedDealerId: null }, () => {
            this.getDealers();

            const foundDealer = this.state.dealers.find(dealer => dealer.all_info.id === loc.dealer_id);

            if (foundDealer && res?.data?.data) {
              const dealer = { ...foundDealer, id: loc.dealer_id };

              if (dealer.locations) {
                dealer.locations = [...dealer.locations, res.data.data];
              } else {
                dealer.locations = [res.data.data];
              }

              this.props.globalDealerUpdate(dealer);
            }
          });
        })
        .catch(error => {
          console.error("Error saving location.", error);
          this.setState({ isSubmittingNewLocation: false });
        });
    });
  };

  handleSave = (data, shouldCloseDetail = true) => {
    this.setState(
      {
        isSubmittingDetail: true,
      },
      () => {
        if (this.state.detailViewMode === "create") {
          Service.createDealer(data)
            .then(result => {
              if (result && result.data && result.data.data) {
                this.handleHideDetailView();
                this.getDealers();
              }
            })
            .catch(error => {
              this.props.setAlert({
                type: "error",
                title: error.message,
              });
              console.error("Error on submitting new dealer.", error);
              this.setState({ isSubmittingDetail: false });
            });
        } else {
          const ponOilRequestData = data.is_pon_oil_enabled
            ? { pon_oil_username: data.pon_oil_username, pon_oil_password: data.pon_oil_password }
            : { pon_oil_username: "", pon_oil_password: "" };

          AppService.updateDealer({ ...data, ...ponOilRequestData })
            .then(() => {
              this.props.globalDealerUpdate(data);
              this.handleHideDetailView();
              this.getDealers();
              if (!shouldCloseDetail) {
                this.getDealer(data.id);
              }
            })
            .catch(error => {
              this.props.setAlert({
                type: "error",
                title: error.message,
              });
              console.error("Error updating dealer.", error);
              this.setState({ isSubmittingDetail: false });
            });
        }
      }
    );
  };

  updateLocationRequestInvalid = () => {
    const { selectedLocation } = this.state;
    const { is_sharebox_enabled, evhc_export_enabled, commercial_name, is_acses_enabled, acses_email, acses_password, has_acses_password } = selectedLocation;

    this.setState({ didSubmitLocation: true });

    if (!commercial_name) return true;

    if (is_sharebox_enabled && ["sharebox_api_client_id", "sharebox_customer_id", "sharebox_session_id"].some(field => !selectedLocation[field])) return true;

    if (evhc_export_enabled && ["evhc_primary_code", "evhc_secondary_code"].some(field => !selectedLocation[field])) return true;

    if (selectedLocation.keylocker_code.length < 4) return true;

    if (is_acses_enabled && (!acses_email || (!has_acses_password && !acses_password))) return true;

    return false;
  };

  handleLocationSave = location => {
    if (this.updateLocationRequestInvalid()) return;

    this.setState({ submittingLocation: true, didSubmitLocation: false }, () => {
      if (!location.photo) {
        const dealer = this.state.dealers.find(dealer => dealer.all_info.id === location.dealer_id);
        let updatedLocation = {
          ...location,
          keylocker_code: Number(location.keylocker_code),
        };

        if (!location.is_robnet_enabled) updatedLocation = { ...updatedLocation, robnet_api_key: "" };
        if (!dealer.all_info.dbb_enabled) updatedLocation = { ...updatedLocation, dbb_enabled: null, dbb_user: null, dbb_password: null };
        if (!location.is_sharebox_enabled)
          updatedLocation = { ...updatedLocation, sharebox_api_client_secret: "", sharebox_weblink_access_key: "", sharebox_private_key: "" };
        if (!location.is_dayplanner_enabled)
          updatedLocation = { ...updatedLocation, automatically_assign_wo_when_check_started: null, automatically_assign_wo_when_intervention_fixed: null };
        if (!location.is_acses_enabled) updatedLocation = { ...updatedLocation, acses_email: "", acses_password: "" };

        return this.updateLocation(updatedLocation);
      }
      let fd = new FormData();
      fd.append("file", location.photo);
      Service.uploadLogo(fd)
        .then(result => {
          if (result && result.data && result.data.data) {
            location.logo = result.data.data.URL;
          }
          this.updateLocation({ ...location, keylocker_code: Number(location.keylocker_code) });
        })
        .catch(error => {
          console.error("Error updating location.", error);
          this.setState({ submittingLocation: false, selectedLocation: null });
        });
    });
  };

  handleLocationDelete = () => {
    let selectedLocation = this.state.selectedLocation;
    this.setState(
      {
        isDeletingLocation: true,
        deleteLocationError: null,
      },
      () => {
        Service.deleteLocation(selectedLocation.id)
          .then(result => {
            this.setState({ submittingLocation: false, selectedLocation: null, isDeletingLocation: false, deleteLocationError: null, isDeleteModalOpen: false }, () => {
              this.getDealers();
            });
          })
          .catch(err => {
            this.setState({
              isDeletingLocation: false,
              deleteLocationError: err.response.data.errors && err.response.data.errors.length > 0 ? err.response.data.errors[0] : "Error Occurred",
            });
            console.log(err);
          });
      }
    );
  };

  handleClearErrorMsg = () => this.setState({ deleteLocationError: null });

  updateLocation = location => {
    Service.updateLocation(location)
      .then(() => {
        this.props.globalLocationUpdate(location);
        this.setState({ submittingLocation: false, selectedLocation: null }, () => {
          this.getDealers();
        });
      })
      .catch(err => {
        const errorMessage = err.response.data.errors && err.response.data.errors.length > 0 ? err.response.data.errors[0] : "Error while updating location";
        this.props.setAlert({
          type: "error",
          title: errorMessage,
        });
        this.setState({ submittingLocation: false, selectedLocation: null });
      });
  };

  handleRefresh = () => {
    this.setState(
      {
        dealers: [],
        originalDealers: [],
        loadingDealers: false,
        selectedStatus: null,
      },
      () => {
        this.getDealers();
      }
    );
  };

  handleActiveChange = (e, data) => {
    let { selectedLocation } = this.state;
    selectedLocation.active = data.checked;
    this.setState({ selectedLocation });
  };

  handleSearchChange = (event, data) => {
    let searchTerm = data.value;
    this.setState({ searchTerm }, () => {
      this.filterDealers();
    });
  };

  handleRowClick = (columnId, rowInfo) => {
    if (!(rowInfo.original.all_info && rowInfo.original.all_info.id)) {
      this.props.setAlert({
        type: "error",
        title: this.props.t("problem_selecting_dealer").message || "Problem selecting this dealer",
      });
      return;
    }
    this.getDealer(rowInfo.original.all_info.id);
  };

  handleLocationRowClick = (columnId, rowInfo) => {
    if (!rowInfo.original.id) {
      this.props.setAlert({
        type: "error",
        title: this.props.t("problem_selecting_location").message || "Problem selecting this location",
      });
      return;
    }
    this.getLocation(rowInfo.original.id);
  };

  handleCloseLocationEditModal = () => {
    this.setState({
      selectedLocation: null,
      isDeleteModalOpen: false,
    });
  };

  handleHideDetailView = () => {
    this.setState({
      detailViewMode: "",
      isDealerDetailVisible: false,
      isSubmittingDetail: false,
      selectedDealer: null,
    });
  };

  handleShowNewLocation = selectedDealer => {
    if (selectedDealer && selectedDealer.all_info && selectedDealer.all_info.id) {
      this.setState({
        isCreateLocationVisible: true,
        selectedDealerId: selectedDealer.all_info.id,
      });
    }
  };

  handleHideNewLocation = () => {
    this.setState({
      isCreateLocationVisible: false,
      selectedDealerId: null,
    });
  };

  handleNewDealer = () => {
    this.setState({
      detailViewMode: "create",
      isDealerDetailVisible: true,
      selectedDealer: this.formatDealer({
        name: null,
        head_line: null,
        footer_line: null,
        city: null,
        street: null,
        postal_code: null,
        phone_nr: null,
        logo: null,
        website_url: null,
        active: false,
        zoho_key: null,
      }),
    });
  };

  handleStatusChange = (event, data) => {
    this.setState(
      {
        selectedStatus: data.value,
      },
      () => {
        this.filterDealers();
      }
    );
  };

  formatDealer = dealer => {
    let formattedDealer = {};
    if (dealer) {
      let locations = [];
      // Nested level - set locations of the dealer
      if (dealer.locations) {
        dealer.locations.forEach(location => {
          location.dealerName = dealer.name;
          location.dealerActive = dealer.active;
          location.info = {
            name: dealer.sys_admin_name,
            email: dealer.sys_admin_email,
            phone_nr: dealer.sys_admin_phone,
            dms_info: dealer.dms_server_info,
            network_info: dealer.network_info,
          };

          locations.push(location);
        });
      }

      formattedDealer.dealerName = dealer.name;
      formattedDealer.dealerActive = dealer.active;
      formattedDealer.all_info = dealer;
      formattedDealer.info = {
        name: dealer.sys_admin_name,
        email: dealer.sys_admin_email,
        phone_nr: dealer.sys_admin_phone,
        dms_info: dealer.dms_server_info,
        network_info: dealer.network_info,
      };
      formattedDealer.locations = locations;
    }
    return formattedDealer;
  };

  renderDealersTable = () => {
    const { dealers, loadingDealers, areLocationsOpen } = this.state;

    if (loadingDealers) {
      return (
        <div className="Table__loading Loader-Placeholder">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
          <section>{this.props.t("loading_dealers").message || "Loading dealers"}</section>
        </div>
      );
    } else {
      return (
        <DealersTable
          dealers={dealers}
          areLocationsOpen={areLocationsOpen}
          onRowClick={this.handleRowClick}
          onLocationClick={this.handleLocationRowClick}
          onNewLocationClick={this.handleShowNewLocation}
        />
      );
    }
  };

  handleChangeSelectedLocation = selectedLocation => {
    this.setState({ selectedLocation });
  };

  render() {
    const {
      selectedStatus,
      isDealerDetailVisible,
      isCreateLocationVisible,
      detailViewMode,
      selectedDealer,
      isSubmittingDetail,
      isSubmittingNewLocation,
      generatedSvcKey,
      didSubmitLocation,
      selectedLocation,
      submittingLocation,
      brands,
      languages,
    } = this.state;

    const statusOptions = [
      {
        text: "All",
        value: "showAll",
      },
      {
        text: "Active",
        value: true,
      },
      {
        text: "Inactive",
        value: false,
      },
    ];

    const isUserAdmin = isAdmin(this.props.authState.user.role_id);

    return (
      <div className="Dealers">
        <SearchPortal>
          <Search
            minCharacters={4}
            className="-large-search"
            input={{
              icon: "search",
              iconPosition: "left",
              placeholder: this.props.t("start_searching_dealers").message || "Start searching dealers...",
            }}
            loading={false}
            showNoResults={false}
            onSearchChange={_.debounce(this.handleSearchChange, 300)}
            fluid
          />
        </SearchPortal>

        <UserMenuActionsPortal>
          <Icon name="refresh" onClick={this.handleRefresh} />
        </UserMenuActionsPortal>

        <SubHeader>
          <Grid stackable className="SubHeader_content_filters -contained-large">
            <Grid.Column width={12}>
              <h1>{this.props.t("dealer admin").message || "Dealers"}</h1>
            </Grid.Column>
            <Grid.Column width={4} className="filters">
              <Button onClick={this.toggleLocationsOpen}>
                {" "}
                <Icon style={{ margin: "0 auto" }} name="arrows alternate vertical" />
              </Button>

              <Dropdown
                placeholder={this.props.t("filter_by_status").message || "Filter by status"}
                className="-pull-right"
                options={statusOptions}
                selection
                value={selectedStatus}
                onChange={this.handleStatusChange}
              />
            </Grid.Column>
          </Grid>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>{this.renderDealersTable()}</Grid.Column>
        </Grid>
        {isDealerDetailVisible && (
          <DealerDetail
            user={this.props.authState.user}
            deleteDealer={this.deleteDealer}
            mode={detailViewMode}
            dealer={detailViewMode === "create" ? selectedDealer : selectedDealer.all_info}
            loading={isSubmittingDetail}
            isAdmin={isUserAdmin}
            generatedSvcKey={generatedSvcKey}
            t={this.props.t}
            setAlert={this.props.setAlert}
            onHideDetail={this.handleHideDetailView}
            onGenerateSvcKey={this.handleGenerateSvcKey}
            onSave={this.handleSave}
          />
        )}

        {selectedLocation && (
          <Modal open={true} onClose={this.handleCloseLocationEditModal} size={"fullscreen"} closeOnDimmerClick={false}>
            <Modal.Header>
              <div>
                {this.props.t("edit_location").message || "Edit location"}
                <Checkbox
                  name="active"
                  label={this.props.t("active").message || "Active"}
                  className="-pull-right"
                  checked={selectedLocation.active}
                  onChange={this.handleActiveChange}
                />
              </div>
            </Modal.Header>
            <Modal.Content>
              <LocationDetail
                user={this.props.authState.user}
                location={selectedLocation}
                active={selectedLocation.active}
                t={this.props.t}
                languages={languages}
                brands={brands}
                loading={submittingLocation}
                onHideDetail={this.handleCloseLocationEditModal}
                onSave={this.handleLocationSave}
                isSubmit={didSubmitLocation}
                onChangeSelectedLocation={this.handleChangeSelectedLocation}
                deleteLocation={this.handleLocationDelete}
                isDeletingLocation={this.state.isDeletingLocation}
                deleteLocationError={this.state.deleteLocationError}
                handleClearErrorMsg={this.handleClearErrorMsg}
                isAdmin={isUserAdmin}
                size="fullscreen"
                isDealersAdminPage={true}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button
                loading={submittingLocation}
                disabled={submittingLocation}
                onClick={() => {
                  this.handleCloseLocationEditModal();
                }}
              >
                <Icon name="close" /> {this.props.t("discard").message || "DISCARD"}
              </Button>

              {this.handleLocationDelete && this.props.authState?.user?.role_id === ROLES.SUPER_ADMIN && (
                <React.Fragment>
                  <Can I="delete" the="locations">
                    <Button
                      color="red"
                      loading={submittingLocation}
                      disabled={submittingLocation}
                      floated="left"
                      onClick={() => {
                        this.setState({ isDeleteModalOpen: true });
                      }}
                    >
                      <Icon name="trash alternate outline" /> {this.props.t("delete_location").message || "Delete location"}
                    </Button>
                  </Can>
                  <CustomConfirm
                    type="danger"
                    isOpen={this.state.isDeleteModalOpen}
                    confirmMsg={this.props.t("confirm_delete_message").message || "Are you sure that you want to delete this? You can't undo this action."}
                    error={this.state.deleteLocationError}
                    isLoading={this.state.isDeletingLocation}
                    handleCancel={() => {
                      this.setState({ isDeleteModalOpen: false });
                    }}
                    handleConfirm={this.handleLocationDelete}
                  />
                </React.Fragment>
              )}

              <Button color="green" floated="right" onClick={() => this.handleLocationSave(selectedLocation)} loading={submittingLocation} disabled={submittingLocation}>
                <Icon name="checkmark" /> {this.props.t("save").message || "SAVE"}
              </Button>
            </Modal.Actions>
          </Modal>
        )}

        {isCreateLocationVisible && (
          <LocationCreate
            languages={languages}
            loading={isSubmittingNewLocation}
            t={this.props.t}
            onHide={this.handleHideNewLocation}
            onSave={this.handleLocationCreate}
          />
        )}

        <FloatingActions items={[]} onRootClick={this.handleNewDealer} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { authState: state.auth };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
    globalLocationUpdate: location => dispatch(globalLocationUpdate(location)),
    globalDealerUpdate: dealer => dispatch(globalDealerUpdate(dealer)),
    getDealersSuccess: dealers => dispatch(getDealersSuccess(dealers)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AdminDealers));
