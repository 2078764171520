import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactTable, { ReactTableDefaults } from "react-table";
import { Dropdown, Card, Icon, Popup, Label } from "semantic-ui-react";
import moment from "moment";
import Lightbox from "react-image-lightbox";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faCheck, faTimes, faQuestion, faHistory, faHome, faSortAlphaDown, faSortAlphaUp } from "@fortawesome/free-solid-svg-icons";

import { Pin, StatusCell, CustomConfirm, UserInput } from "../../components";
import { ANSWER_STATUSES, getDealerAndLocationById, copyToClipboard, removeGenerationParam } from "../../util/common";
import { renderMedia, isRealStatus, APPOINTMENT_STATUSES } from "../../modules/Appointments/common";
import { MANUFACTURER_REQUEST_STATUSES } from "../../modules/ManufacturerDashboard/request_statuses";
import { setAlert } from "../../modules/App/store";
import { ORDER_BY } from "../../modules/WarrantyDashboard";
import { ROLES } from "../../modules/Users/roles";
import { getShopIconColor } from "../Pin/util";
import { PIN_STATUS, WARRANTY_TYPE } from "../Pin/enum";

import Service from "./service";
import PinOptions from "./PinOptions";
import "./PinTable.scss";

const initStatuses = statuses => {
  let appStatuses = [];
  if (Array.isArray(statuses)) {
    appStatuses = statuses
      .filter(status => isRealStatus(status.identifier) && status.identifier !== APPOINTMENT_STATUSES.CANCELED)
      .map(status => ({
        ...status,
        isActive: true,
      }));
  }

  appStatuses = _.sortBy(appStatuses, "order");

  return appStatuses;
};

const CAR_NR_COLUMN = {
  REG_NR: 1,
  VIN_NR: 2,
};

class PinTable extends Component {
  state = {
    statuses: initStatuses(this.props.globalState?.selectedLocation?.statuses),
    photoIndex: 0,
    images: [],
    videos: [],
    isImageGalleryVisible: false,
    pinStatusOptions: [],
    warrantyTypeOptions: [],
    pinTypesOptions: [],
    isUpdateManufacturerRequestConfirmationVisible: false,
    selectedManufacturerRequest: null,
    isUpdatingManufacturerRequest: false,
    selectedCarNumberColumn: CAR_NR_COLUMN.REG_NR,
  };

  handleAppointmentClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/${result.appointment_id}`, "_blank");
      return;
    }
    this.props.onAppointmentClick(result);
  };

  handleCarClick = result => {
    if (this.props.externalLink) {
      window.open(`${document.location.origin}/#!/appointments/car/${result.car_id}`, "_blank");
      return;
    }
    this.props.onCarClick(result.car_id, result.dealer_location_id);
  };

  componentDidMount() {
    if (!this.props.pinTypesOptions && !this.props.warrantyTypeOptions && !this.props.pinStatusOptions) {
      PinOptions.getPinOptions()
        .then(result => {
          this.setState({ ...result });
        })
        .catch(error => {
          console.log("Error getting pin options", error);
          this.props.setAlert({ type: "error", title: "Cannot load pin options" });
        });
    } else {
      const { pinTypesOptions, warrantyTypeOptions, pinStatusOptions } = this.props;
      this.setState({ pinTypesOptions, warrantyTypeOptions, pinStatusOptions });
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.globalState?.selectedLocation?.statuses !== this.props.globalState?.selectedLocation?.statuses) {
      this.setState({ statuses: initStatuses(this.props.globalState?.selectedLocation?.statuses) });
    }

    if (
      prevProps.pinTypesOptions !== this.props.pinTypesOptions ||
      prevProps.warrantyTypeOptions !== this.props.warrantyTypeOptions ||
      prevProps.pinStatusOptions !== this.props.pinStatusOptions
    ) {
      this.setState({ pinTypesOptions: this.props.pinTypesOptions, warrantyTypeOptions: this.props.warrantyTypeOptions, pinStatusOptions: this.props.pinStatusOptions });
    }
  };

  renderStatus = status => {
    if (status === -1) return null;
    if (typeof status === "undefined" || status === "") {
      status = 4;
    }

    let backgroundColor = ANSWER_STATUSES[status] ? ANSWER_STATUSES[status].color : "#fff";
    let style = {
      backgroundColor,
      color: "white",
    };

    return (
      <div className="QuestionStatus" style={style}>
        {ANSWER_STATUSES[status].text}
      </div>
    );
  };

  renderMediaButton = d => {
    if (!d.images && !d.video) return null;

    return (
      <Popup
        basic
        on="click"
        hideOnScroll
        className="Warranty_media_popup"
        position="left center"
        wide="very"
        flowing
        style={{ zIndex: "999" }}
        onOpen={() => this.setState({ images: d.images, videos: d.video })}
        content={<div>{renderMedia(true, d, this.state.images, this.state.videos, this.handleMediaToggle, null, false)}</div>}
        trigger={
          <div className="QuestionStatus" style={{ color: "white", backgroundColor: "#4AB5AD", cursor: "pointer" }}>
            <Icon name="images" style={{ margin: "auto" }} /> {(d.images && d.images.length) + (d.video && d.video.length)}
          </div>
        }
      />
    );
  };

  handleMediaToggle = () => {
    this.setState({ isImageGalleryVisible: true });
  };

  handleCopyToClipboard = (text, title) => {
    copyToClipboard(text);
    this.props.setAlert({ type: "success", title });
  };

  handleChangeCarNumberColumn = selectedCarNumberColumn => this.setState({ selectedCarNumberColumn });

  renderImageGallery = () => {
    const { photoIndex, images } = this.state;

    return (
      <Lightbox
        mainSrc={removeGenerationParam(images[photoIndex].url)}
        nextSrc={removeGenerationParam(images[(photoIndex + 1) % images.length].url)}
        prevSrc={removeGenerationParam(images[(photoIndex + images.length - 1) % images.length].url)}
        onCloseRequest={() => this.setState({ isImageGalleryVisible: false })}
        onMovePrevRequest={() =>
          this.setState({
            photoIndex: (photoIndex + images.length - 1) % images.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (photoIndex + 1) % images.length,
          })
        }
      />
    );
  };

  getOptionalColumns = () => {
    const optionalColumns = [];
    const { t, showRegNr, externalLink, authState, dealerNames, locationNames, dateToOrderBy } = this.props;
    const isManufacturer = authState.user.role_id === ROLES.MANUFACTURER;

    if (showRegNr)
      optionalColumns.push({
        id: "regNo",
        Header: () => (
          <Dropdown
            trigger={
              <span className="-table-header">
                {this.state.selectedCarNumberColumn === CAR_NR_COLUMN.REG_NR ? t("reg_number").message || "Reg #" : t("vin_nr").message || "Vin #"}
                <Icon name="filter" />
              </span>
            }
            fluid
            inline
            icon={null}
            floating
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => this.handleChangeCarNumberColumn(CAR_NR_COLUMN.REG_NR)}
                title={t("reg_number").message || "Reg #"}
                text={t("reg_number").message || "Reg #"}
                className="selected-sorting-option"
                active={this.state.selectedCarNumberColumn === CAR_NR_COLUMN.REG_NR}
              />
              <Dropdown.Item
                onClick={() => this.handleChangeCarNumberColumn(CAR_NR_COLUMN.VIN_NR)}
                title={t("vin_nr").message || "Vin #"}
                text={t("vin_nr").message || "Vin #"}
                className="selected-sorting-option"
                active={this.state.selectedCarNumberColumn === CAR_NR_COLUMN.VIN_NR}
              />
            </Dropdown.Menu>
          </Dropdown>
        ),
        headerClassName: "-overflow-inherit -border-right-none",
        accessor: d => {
          if (this.state.selectedCarNumberColumn === CAR_NR_COLUMN.REG_NR) {
            return !isManufacturer || !d.manufacturer_request || d.manufacturer_request.status === MANUFACTURER_REQUEST_STATUSES.APPROVED ? (
              <span className="-cursor-pointer" onClick={() => this.handleCarClick(d)}>
                {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                {d.appointment.reg_number}
              </span>
            ) : (
              <span>{d.appointment.reg_number}</span>
            );
          } else {
            return (
              <Popup
                trigger={
                  d.appointment.vin_nr ? (
                    <div
                      className="VinNrContainer"
                      onClick={() => this.handleCopyToClipboard(d.appointment.vin_nr, t("vin_nr_copied").message || "Vin # copied to clipboard")}
                    >
                      <Icon name="copy outline" />
                      <div className="vin_nr">{d.appointment.vin_nr}</div>
                    </div>
                  ) : (
                    ""
                  )
                }
                position="top center"
                content={<span>{d.appointment.vin_nr}</span>}
                pinned={true}
              />
            );
          }
        },
        maxWidth: 115,
      });

    if (dealerNames && locationNames) {
      optionalColumns.push(
        {
          Header: t("dealer").message || "Dealer",
          id: "dealer_id",
          accessor: d => dealerNames[d.manufacturer_request?.dealer_id],
          maxWidth: 100,
        },
        {
          Header: t("location").message || "Location",
          id: "dealer_location_id",
          accessor: d => locationNames[d.manufacturer_request?.dealer_location_id],
          maxWidth: 100,
        }
      );
    }

    if (!dateToOrderBy) {
      optionalColumns.push({
        Header: t("pin_date").message || "Pin Date",
        id: "pinDate",
        accessor: d =>
          d.updated_on && (moment.isMoment(d.updated_on) || !d.updated_on.startsWith("0001-01-01T00:00:00")) // might be time zoned
            ? moment(d.updated_on).format("DD-MM-YYYY")
            : "",
        maxWidth: 70,
      });
    }

    return optionalColumns;
  };

  getWarrantyIconColumns = () => {
    const columns = [];

    if (this.props.isWarrantyDashboard) {
      columns.push(
        {
          Header: <Icon name="smile" />,
          id: "customer_approved",
          accessor: d => {
            const status = d.question_result_id ? d.question_result.customer_approved : d.intervention.customer_ok;

            return (
              <div className="no-label-icon">
                <Icon name={status ? "thumbs up" : "thumbs down"} color={status ? "green" : "red"} />
              </div>
            );
          },
          maxWidth: 30,
        },
        {
          Header: <Icon name="car" />,
          id: "mechanic_fixed",
          Cell: row => (
            <div className="no-label-icon">
              <Icon name={row.original.mechanic_fixed ? "check" : "close"} color={row.original.mechanic_fixed ? "green" : "red"} />
            </div>
          ),
          maxWidth: 30,
        },
        {
          id: "order_status",
          Cell: row => (
            <div className="label-icon-container">
              <Label color={getShopIconColor(row.original.order_status)} style={{ display: !row.original.order_status ? "none" : "inline-flex" }}>
                <Icon name="shop" />
              </Label>
            </div>
          ),
          maxWidth: 45,
        }
      );
    }

    return columns;
  };

  setUpdateRequestConfirm = isUpdateManufacturerRequestConfirmationVisible => {
    this.setState({ isUpdateManufacturerRequestConfirmationVisible });
  };

  getManufacturerRequestColumn = () => {
    const finalColumn = [];

    if (this.props.items?.some(item => item.manufacturer_request)) {
      finalColumn.push({
        id: "manufacturerPopup",
        Header: "",
        accessor: d => this.renderManufacturerPopup(d),
        maxWidth: 42,
      });
    }

    return finalColumn;
  };

  getPopupIcon = manufacturerRequest => {
    if (manufacturerRequest.status === MANUFACTURER_REQUEST_STATUSES.APPROVED) {
      return (
        <div className="QuestionStatus" style={{ backgroundColor: "green", color: "white" }}>
          <FontAwesomeIcon icon={faCheck} />
        </div>
      );
    } else if (manufacturerRequest.status === MANUFACTURER_REQUEST_STATUSES.DECLINED) {
      return (
        <div className="QuestionStatus" style={{ backgroundColor: "red", color: "white" }}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      );
    } else if (manufacturerRequest.status === MANUFACTURER_REQUEST_STATUSES.PENDING) {
      const canUpdate = this.props.authState.user.acl["manufacturer"]?.some(acl => acl.activity === "update-request");

      return (
        <div
          className={canUpdate ? "QuestionStatus -cursor-pointer" : "QuestionStatus"}
          onClick={() => canUpdate && this.setState({ selectedManufacturerRequest: manufacturerRequest }, () => this.setUpdateRequestConfirm(true))}
          style={{ backgroundColor: "blue", color: "white" }}
        >
          <FontAwesomeIcon icon={this.props.authState.user.role_id !== ROLES.MANUFACTURER ? faQuestion : faHistory} />
        </div>
      );
    }
  };

  getPopupContent = manufacturerRequest => {
    const { t, authState } = this.props;

    if (manufacturerRequest.status === MANUFACTURER_REQUEST_STATUSES.APPROVED) {
      return (
        <div>
          {t("this_access_will_expire_on").message || "This access will expire on:"} {moment(manufacturerRequest.expire_on).format("DD/MM/YYYY")}
        </div>
      );
    } else if (manufacturerRequest.status === MANUFACTURER_REQUEST_STATUSES.DECLINED) {
      return (
        <div>
          <div>{t("decline_for_the_following_reason").message || "Declined for the following reason:"}</div>
          <br />
          <div>{manufacturerRequest.decline_reason}</div>
        </div>
      );
    } else if (manufacturerRequest.status === MANUFACTURER_REQUEST_STATUSES.PENDING) {
      if (authState.user.role_id === ROLES.MANUFACTURER) return <div>{t("request_pending_approval").message || "Request pending approval"}</div>;

      return (
        <div>
          <div>
            {manufacturerRequest.manufacturer.first_name} {manufacturerRequest.manufacturer.last_name}{" "}
            {t("requested_access_for_the_following_reason").message || "requested access for the following reason:"}
          </div>
          <br />
          <div>{manufacturerRequest.request_reason}</div>
        </div>
      );
    }
  };

  renderManufacturerPopup = pin => {
    if (!pin.manufacturer_request) return null;
    return (
      <Popup basic on="hover" position="left center" content={this.getPopupContent(pin.manufacturer_request)} trigger={this.getPopupIcon(pin.manufacturer_request)} />
    );
  };

  handleUpdateManufacturerRequest = (manufacturer_request_status, manufacturer_request_id, decline_reason = null) => {
    this.setState({ isUpdatingManufacturerRequest: true }, () => {
      Service.updateRequest({ manufacturer_request_id, manufacturer_request_status, decline_reason })
        .then(res => {
          this.props.onUpdateManufacturerRequest(manufacturer_request_status, manufacturer_request_id, decline_reason);
          this.setState({ isUpdatingManufacturerRequest: false, isUpdateManufacturerRequestConfirmationVisible: false });
        })
        .catch(err => {
          this.setState({ isUpdatingManufacturerRequest: false });
          console.log("Error occured when updating request: ", err);
        });
    });
  };

  handleOnPin = (groupedItem, newPinLog) => {
    const { onLogChanged } = this.props;

    if (newPinLog?.length < 1) return onLogChanged(newPinLog);
    newPinLog[0].appointment = groupedItem.appointment;

    if (groupedItem.intervention) newPinLog[0].intervention = groupedItem.intervention;
    else if (groupedItem.question_result) newPinLog[0].question_result = groupedItem.question_result;

    onLogChanged(newPinLog);
  };

  renderSortingDropdownOption = dateToOrderBy => {
    const { t } = this.props;

    switch (dateToOrderBy) {
      case ORDER_BY.APPOINTMENT_DATE_ASCENDING:
        return (
          <>
            <span>{t("Appointment_date").message || "Appointment Date"}</span> <FontAwesomeIcon icon={faSortAlphaDown} />
          </>
        );
      case ORDER_BY.APPOINTMENT_DATE_DESCENDING:
        return (
          <>
            <span>{t("Appointment_date").message || "Appointment Date"}</span> <FontAwesomeIcon icon={faSortAlphaUp} />
          </>
        );
      case ORDER_BY.PIN_UPDATED_ON_ASCENDING:
        return (
          <>
            <span>{t("last_updated").message || "Last updated"}</span> <FontAwesomeIcon icon={faSortAlphaDown} />
          </>
        );
      case ORDER_BY.PIN_UPDATED_ON_DESCENDING:
        return (
          <>
            <span>{t("last_updated").message || "Last updated"}</span> <FontAwesomeIcon icon={faSortAlphaUp} />
          </>
        );
    }
  };

  getDateSortingDropdownOptions = () => {
    return [ORDER_BY.APPOINTMENT_DATE_ASCENDING, ORDER_BY.APPOINTMENT_DATE_DESCENDING, ORDER_BY.PIN_UPDATED_ON_ASCENDING, ORDER_BY.PIN_UPDATED_ON_DESCENDING].map(
      option => ({
        value: option,
        children: this.renderSortingDropdownOption(option),
        className: "selected-sorting-option",
      })
    );
  };

  renderTable = () => {
    const {
      t,
      items,
      nb_items,
      pages,
      loading,
      car,
      onFetchData,
      externalLink,
      page,
      authState,
      dateToOrderBy,
      onChangeDateToOrderBy,
      onChangeClaimNrToOrderBy,
      claimNrOrderBy,
      globalState,
    } = this.props;
    const { pinTypesOptions, pinStatusOptions, warrantyTypeOptions, statuses } = this.state;
    if (!items || items.length < 1) return <div className="Table__no-results">{t("no_pins_found").message || "No pins found"}</div>;
    const allpinStatusOptions = [...pinStatusOptions];
    allpinStatusOptions[PIN_STATUS.DELETED] = "deleted";

    const isManufacturer = authState.user.role_id === ROLES.MANUFACTURER;

    return (
      <ReactTable
        className="PinTable -floated-table"
        data={items || []}
        page={page - 1}
        pages={pages === null ? -1 : pages}
        showPagination={pages > 1}
        renderPageJump={({ onChange, value, onBlur, onKeyPress, inputType, pageJumpText }) => (
          <div className="-pageJump">
            <UserInput
              aria-label={pageJumpText}
              type={inputType}
              onChange={evt => {
                onChange(evt);

                const newPage = evt.target.value - 0;
                if (!Number.isNaN(newPage) && newPage > 0 && newPage <= pages) onFetchData(newPage);
              }}
              value={page}
              onBlur={onBlur}
              onKeyPress={onKeyPress}
            />
          </div>
        )}
        loading={loading}
        manual
        onFetchData={(state, instance) => onFetchData(state.page + 1)}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        minRows={0}
        loadingText=""
        nextText={t("next").message || "Next"}
        previousText={t("previous").message || "Previous"}
        pageText={t("page").message || "Page"}
        ofText={t("of").message || "of"}
        onPageChange={() => {
          try {
            document.querySelector(".App__module").scrollTo(0, 0);
          } catch (e) {
            document.querySelector(".App__module").scrollTop = 0; // IE Fix
          }
        }}
        column={{
          ...ReactTableDefaults.column,
          headerClassName: "ReactTable__column-header -text-ellipsis",
          className: "ReactTable__column",
        }}
        columns={[
          {
            Header: () => {
              if (dateToOrderBy)
                return (
                  <Dropdown
                    trigger={
                      <span className="-table-header">
                        {[ORDER_BY.APPOINTMENT_DATE_ASCENDING, ORDER_BY.APPOINTMENT_DATE_DESCENDING].includes(dateToOrderBy)
                          ? t("Appointment_date").message || "Appointment Date"
                          : t("last_updated").message || "Last updated"}
                        <Icon name="filter" />
                      </span>
                    }
                    fluid
                    inline
                    icon={null}
                    floating
                    onChange={(_e, { value }) => onChangeDateToOrderBy(value)}
                    value={this.props.dateToOrderBy}
                    options={this.getDateSortingDropdownOptions()}
                  />
                );

              return t("Appointment_date").message || "Appointment Date";
            },
            headerClassName: "-overflow-inherit -border-right-none",
            id: "appointmentDate",
            accessor: d => {
              if (dateToOrderBy) {
                switch (dateToOrderBy) {
                  case ORDER_BY.APPOINTMENT_DATE_ASCENDING:
                  case ORDER_BY.APPOINTMENT_DATE_DESCENDING:
                    break;
                  case ORDER_BY.PIN_UPDATED_ON_ASCENDING:
                  case ORDER_BY.PIN_UPDATED_ON_DESCENDING:
                    return d.updated_on ? moment(d.updated_on).format("DD-MM-YYYY") : "";
                  default:
                    break;
                }
              }

              if (d.appointment.time_car_app && (moment.isMoment(d.appointment.time_car_app) || !d.appointment.time_car_app.startsWith("0001-01-01T00:00:00"))) {
                return moment(d.appointment.time_car_app).format("DD-MM-YYYY");
              }

              return "";
            },
            maxWidth: 135,
          },
          {
            Header: t("status").message || "Status",
            id: "status",
            className: "-status-column",
            accessor: d => ({
              name: d.appointment_status_name,
              color: d.appointment_status_color,
              id: d.appointment.appointment_status_identifier,
              customcom_status: d.customcom_status,
            }),
            Cell: row => (
              <StatusCell
                value={{ id: row.original.appointment.appointment_status_identifier, customcom_status: row.original.appointment.customcom_status }}
                statuses={statuses}
              />
            ),
            minWidth: 50,
            maxWidth: 50,
          },
          {
            id: "woNr",
            Header: (
              <>
                {t("WO Nr").message || "WO"} {nb_items && <span className="total-appointments">{nb_items}</span>}
              </>
            ),
            accessor: d =>
              d.wo_nr === 0 ? (
                ""
              ) : !isManufacturer || !d.manufacturer_request || d.manufacturer_request.status === MANUFACTURER_REQUEST_STATUSES.APPROVED ? (
                <Popup
                  trigger={
                    <span
                      className="-cursor-pointer"
                      onClick={() => {
                        this.handleAppointmentClick(d);
                      }}
                    >
                      {externalLink ? <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} /> : <Icon name="info circle" />}
                      {d.appointment.wo_nr}
                    </span>
                  }
                  position="top center"
                  content={d.appointment.wo_nr}
                  pinned={true}
                />
              ) : (
                <Popup trigger={<span>{d.appointment.wo_nr}</span>} position="top center" content={d.appointment.wo_nr} pinned={true} />
              ),
            maxWidth: 80,
          },
          ...this.getOptionalColumns(),

          {
            Header: t("pin_type").message || "Pin Type",
            id: "pinType",
            accessor: d => t(pinTypesOptions[d.pin_type_id]).message || pinTypesOptions[d.pin_type_id],
            maxWidth: 80,
          },
          {
            Header: t("warranty_type").message || "Warranty Type",
            id: "warrantyType",
            accessor: d => {
              if (d.warranty_type_id === WARRANTY_TYPE.NOT_SET) return "";
              return t(warrantyTypeOptions[d.warranty_type_id]).message || warrantyTypeOptions[d.warranty_type_id];
            },
            maxWidth: 95,
          },
          {
            id: "pinStatus",
            Header: t("pin_status").message || "Pin Status",
            accessor: d => {
              if (d.pin_status_id === PIN_STATUS.NOT_SET) return "";
              return t(allpinStatusOptions[d.pin_status_id]).message || allpinStatusOptions[d.pin_status_id];
            },
            maxWidth: 90,
          },
          {
            id: "support",
            Header: t("support_nr").message || "Support #",
            accessor: d => {
              if (car) return d.support_nr;

              return (
                <Popup
                  trigger={
                    d.support_nr && (
                      <div
                        className="SupportNrContainer"
                        onClick={() => this.handleCopyToClipboard(d.support_nr, t("support_nr_copied").message || "Support # copied to clipboard")}
                      >
                        <Icon name="copy outline" />
                        <div className="support_nr">{d.support_nr}</div>
                      </div>
                    )
                  }
                  position="top center"
                  content={d.support_nr}
                  pinned={true}
                />
              );
            },
            maxWidth: 80,
          },
          {
            id: "claim",
            Header: t("claim_nr").message || "Claim #",
            Header: (
              <span className="sort-icon">
                {t("claim_nr").message || "Claim #"}
                {!claimNrOrderBy && <Icon name="caret up" className="inactive-sort-icon" onClick={() => onChangeClaimNrToOrderBy(ORDER_BY.CLAIM_NR_ASCENDING)} />}
                {claimNrOrderBy === ORDER_BY.CLAIM_NR_ASCENDING && <Icon name="caret up" onClick={() => onChangeClaimNrToOrderBy(ORDER_BY.CLAIM_NR_DESCENDING)} />}
                {claimNrOrderBy === ORDER_BY.CLAIM_NR_DESCENDING && <Icon name="caret down" onClick={() => onChangeClaimNrToOrderBy(null)} />}
              </span>
            ),
            accessor: d => {
              if (car) return d.claim_nr;

              return (
                <Popup
                  trigger={
                    d.claim_nr && (
                      <div
                        className="ClaimNrContainer"
                        onClick={() => this.handleCopyToClipboard(d.claim_nr, t("claim_nr_copied").message || "Claim # copied to clipboard")}
                      >
                        <Icon name="copy outline" />
                        <div className="claim_nr">{d.claim_nr}</div>
                      </div>
                    )
                  }
                  position="top center"
                  content={d.claim_nr}
                  pinned={true}
                />
              );
            },
            maxWidth: 80,
          },
          {
            id: "reference",
            Header: t("reference_nr").message || "Ref #",
            accessor: d => {
              if (car) return d.ref_nr;

              return (
                <Popup
                  trigger={
                    d.ref_nr && (
                      <div className="RefNrContainer" onClick={() => this.handleCopyToClipboard(d.ref_nr, t("ref_nr_copied").message || "Ref # copied to clipboard")}>
                        <Icon name="copy outline" />
                        <div className="ref_nr">{d.ref_nr}</div>
                      </div>
                    )
                  }
                  position="top center"
                  content={d.ref_nr}
                  pinned={true}
                />
              );
            },
            maxWidth: 80,
          },
          {
            id: "brand",
            Header: t("brand").message || "Brand",
            accessor: d => <span>{d.appointment.car_make}</span>,
            maxWidth: 80,
          },
          {
            id: "intervention",
            Header: t("intervention_question_result").message || "Intervention/Question Result",
            accessor: d => (d.question_result ? <span>{d.question_result.title}</span> : <span>{d.intervention.title}</span>),
            minWidth: 80,
          },
          {
            id: "media",
            Header: "",
            accessor: d => this.renderMediaButton(d),
            maxWidth: 42,
          },
          {
            id: "status",
            Header: "",
            accessor: d => {
              if (d.intervention_id) {
                return null;
              }
              return this.renderStatus(d.question_result.status);
            },
            maxWidth: 42,
          },
          ...this.getWarrantyIconColumns(),
          {
            id: "pin",
            Header: "",
            accessor: d => {
              if (this.props.car) d.appointment.car = this.props.car;

              return (
                <Pin
                  appointment={d.appointment}
                  manufacturer_request={d.manufacturer_request}
                  intervention={d.intervention}
                  question_result={d.question_result}
                  pinLog={d.history}
                  onPin={pinLog => this.handleOnPin(d, pinLog)}
                  onDelete={reason => this.props.onPinDeleted(d, reason)}
                />
              );
            },
            maxWidth: 42,
          },
          {
            id: "dealer_location",
            Header: "",
            accessor: d => {
              const { dealer, location } = getDealerAndLocationById(globalState.dealers, d.dealer_location_id);

              return (
                <Popup
                  basic
                  on="hover"
                  position="left center"
                  content={
                    <Card.Group>
                      <Card>
                        <Card.Content>
                          <Card.Header content={t("dealer_location").message || "Dealer/Location"} />
                          <Card.Description content={dealer.name} />
                          <Card.Description content={location.name} />
                        </Card.Content>
                      </Card>
                    </Card.Group>
                  }
                  trigger={
                    <div className="QuestionStatus" style={{ backgroundColor: "purple", color: "white" }}>
                      <FontAwesomeIcon icon={faHome} />
                    </div>
                  }
                />
              );
            },
            maxWidth: 42,
          },
          ...this.getManufacturerRequestColumn(),
        ]}
      />
    );
  };

  renderUpdateManufacturerRequestConfirm = () => {
    const { selectedManufacturerRequest, isUpdatingManufacturerRequest } = this.state;
    const { t } = this.props;

    const confirmMsg = (
      <div className="-margin-bottom-10">
        <div className="-margin-bottom-5">
          {t("approve_or_decline_manufacturer_request").message || "Once you approved or declined this request, you can't undo this action."}
        </div>

        <div className="-margin-bottom-5">
          <b>{t("manufacturer").message || "Manufacturer"}: </b>
          {`${selectedManufacturerRequest.manufacturer.first_name} ${selectedManufacturerRequest.manufacturer.last_name}`}
        </div>

        <div className="-margin-bottom-5">
          <b>{t("reason").message || "Reason"}: </b>
          {selectedManufacturerRequest.request_reason}
        </div>
      </div>
    );

    return (
      <CustomConfirm
        type="warning"
        isLoading={isUpdatingManufacturerRequest}
        isOpen={true}
        confirmText={t("approve").message || "Approve"}
        handleConfirm={() => this.handleUpdateManufacturerRequest(MANUFACTURER_REQUEST_STATUSES.APPROVED, selectedManufacturerRequest.id)}
        handleCancel={() => this.setUpdateRequestConfirm(false)}
        confirmMsg={confirmMsg}
        declineMandatoryNote={t("enter_a_reason_to_decline").message || "Please enter a reason if you want to decline this request"}
        declineText={t("decline").message || "Decline"}
        handleDecline={declineReason => this.handleUpdateManufacturerRequest(MANUFACTURER_REQUEST_STATUSES.DECLINED, selectedManufacturerRequest.id, declineReason)}
      />
    );
  };

  render() {
    const { isImageGalleryVisible, isUpdateManufacturerRequestConfirmationVisible } = this.state;

    return (
      <div className="PinTable">
        {this.renderTable()}
        {isImageGalleryVisible && this.renderImageGallery()}
        {isUpdateManufacturerRequestConfirmationVisible && this.renderUpdateManufacturerRequestConfirm()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global, authState: state.auth };
};

const mapDispatchToProps = dispatch => ({
  setAlert: alertOptions => dispatch(setAlert(alertOptions)),
});

export { PinOptions, initStatuses };

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PinTable));
