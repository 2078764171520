import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Button, Icon, Grid, Label, Form, Image, Checkbox, Popup, Table, Radio } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AceEditor from "react-ace";
import "brace/mode/html";
import "brace/theme/monokai";

import { SubHeader, ColorPicker, UserInput } from "../../components";
import { setAlert } from "../App/store";
import Can from "../Can";
import Service from "./service";

class CommunicationSettings extends Component {
  state = {
    loadingSettings: true,
    isUploading: false,
    loadingReportTemplates: true,
    settings: {},
    reportTemplates: [],
    isSubmit: false,
  };

  componentDidMount() {
    this.getSettings();
    this.getReportTemplates();
  }

  componentDidUpdate(prevProps) {
    if (this.props.globalState.selectedLocation.id !== prevProps.globalState.selectedLocation.id) {
      this.getSettings();
    }
  }

  getSettings = () => {
    this.setState({ loadingSettings: true }, () => {
      Service.getSettings({ dealer_location_id: this.props.globalState.selectedLocation.id })
        .then(result => this.setState({ settings: result?.data?.data?.settings || {}, loadingSettings: false }))
        .catch(err => {
          this.setState({ loadingSettings: false }, () => {
            this.props.setAlert({ type: "error", title: err.message });
            console.error("Error getting settings.", err);
          });
        });
    });
  };

  getReportTemplates = () => {
    this.setState({ loadingReportTemplates: true }, () => {
      Service.getReportTemplates({ dealer_id: this.props.globalState.selectedDealer.id })
        .then(result => {
          let reportTemplates = [];
          if (result?.data?.data) {
            result.data.data.forEach(item => {
              reportTemplates.push({
                key: item.id,
                value: item.id,
                text: item.name,
              });
            });
          }

          this.setState({ reportTemplates, loadingReportTemplates: false });
        })
        .catch(err => {
          this.setState({ loadingReportTemplates: false }, () => {
            this.props.setAlert({ type: "error", title: err.message });
            console.error("Error getting settings.", err);
          });
        });
    });
  };

  handleUploadLogo = async () => {
    const { settings } = this.state;

    if (!settings.logo || typeof settings.logo === "string") return settings.logo;

    let fd = new FormData();
    fd.append("file", settings.logo);

    return Service.uploadLogo(fd)
      .then(result => {
        if (result?.data?.data) return result.data.data.URL;
      })
      .catch(error => {
        console.error("Error uploading logo.", error);
        throw Error(this.props.t("failed_to_upload_image").message || "Failed to upload an image");
      });
  };

  handleUploadWelcomePicture = async () => {
    const { settings } = this.state;

    if (!settings.welcome_picture || typeof settings.welcome_picture === "string") return settings.welcome_picture;

    let fd = new FormData();
    fd.append("file", settings.welcome_picture);

    return Service.uploadWelcomePic(fd)
      .then(result => {
        if (result?.data?.data) return result.data.data.URL;
      })
      .catch(error => {
        console.error("Error uploading welcome pic.", error);
        throw Error(this.props.t("failed_to_upload_image").message || "Failed to upload an image");
      });
  };

  handleUploadDeskCheckinStartImage = async () => {
    const { settings } = this.state;

    if (!settings.counter_tablet_home_url || typeof settings.counter_tablet_home_url === "string") return settings.counter_tablet_home_url;

    const fd = new FormData();
    fd.append("file", settings.counter_tablet_home_url);

    return Service.uploadDeskCheckinImage(fd)
      .then(result => {
        if (result?.data?.data) return result.data.data.URL;
      })
      .catch(error => {
        console.error("Error uploading desk check-in image", error);
        throw Error(this.props.t("failed_to_upload_image").message || "Failed to upload an image");
      });
  };

  submitSettings = () => {
    const {
      check_in_testing_mode,
      check_in_testing_email,
      check_in_testing_phone,
      diagnose_cc_testing_mode,
      diagnose_cc_testing_email,
      diagnose_cc_testing_phone,
      repair_cc_testing_mode,
      repair_cc_testing_email,
      repair_cc_testing_phone,
    } = this.state.settings;

    const { chatshipper_enabled, chatshipper_email } = this.props.globalState.selectedDealer;

    const chatshipperEnabled = !!(chatshipper_enabled && chatshipper_email);

    const missingTestingInfo =
      (check_in_testing_mode && (!check_in_testing_email || (chatshipperEnabled && !check_in_testing_phone))) ||
      (diagnose_cc_testing_mode && (!diagnose_cc_testing_email || (chatshipperEnabled && !diagnose_cc_testing_phone))) ||
      (repair_cc_testing_mode && (!repair_cc_testing_email || (chatshipperEnabled && !repair_cc_testing_phone)));

    if (missingTestingInfo) {
      this.props.setAlert({
        type: "error",
        title:
          this.props.t("cc_testing_contact_missing").message ||
          "In testing mode, email is required by default. If you have SMS enabled, a phone number must also be provided.",
      });

      return;
    }

    this.setState({ isSubmit: true, isUploading: true }, async () => {
      try {
        const logo = await this.handleUploadLogo();
        const welcome_picture = await this.handleUploadWelcomePicture();
        const counter_tablet_home_url = await this.handleUploadDeskCheckinStartImage();

        const settings = { ...this.state.settings, logo, welcome_picture, counter_tablet_home_url };

        await Service.setSettings({ settings });
        this.setState({ isSubmit: false, isUploading: false, settings });
      } catch (err) {
        this.setState({ isSubmit: false, isUploading: false }, () => {
          this.props.setAlert({ type: "error", title: err.message });
          console.error("Error saving medium.", err);
        });
      }
    });
  };

  handleColorChange = color => {
    this.setState(state => ({ settings: { ...state.settings, color } }));
  };

  handleImageChange = e => {
    const { files, name } = e.target;

    if (!files || !name) return;

    this.setState(state => ({ settings: { ...state.settings, [name]: files[0] } }));
  };

  handleSupportTextChange = support_text => {
    this.setState(state => ({ settings: { ...state.settings, support_text } }));
  };

  handleCheckboxChange = (_e, data) => {
    this.setState(state => ({ settings: { ...state.settings, [data.name]: data.checked } }));
  };

  handleKioskCheckinCheckboxChange = (_e, { name, checked }) => {
    const { is_customer_name_in_kiosk_checkin_enabled, is_customer_signature_in_kiosk_checkin_enabled } = this.state.settings;

    if (!checked && (!is_customer_name_in_kiosk_checkin_enabled || !is_customer_signature_in_kiosk_checkin_enabled)) {
      this.setState(state => ({
        settings: { ...state.settings, is_customer_signature_in_kiosk_checkin_enabled: true, is_customer_name_in_kiosk_checkin_enabled: true, [name]: checked },
      }));
    } else {
      this.setState(state => ({ settings: { ...state.settings, [name]: checked } }));
    }
  };

  handleCheckInDaysPrior = (_e, data) => {
    this.setState(state => ({ settings: { ...state.settings, check_in_days_prior: parseInt(data.value, 10) } }));
  };

  handleInputChange = (_e, data) => {
    if (Object.keys(this.state.settings).length === 0) return;

    this.setState(state => ({ settings: { ...state.settings, [data.name]: data.value } }));
  };

  render() {
    const { settings, isSubmit, reportTemplates, isUploading } = this.state;
    const { t, globalState } = this.props;
    const { is_keylocker_enabled, desk_check_in_enabled } = globalState.selectedLocation;

    return (
      <div className="Settings">
        <SubHeader>
          <Grid.Column width={16}>
            <h1>{this.props.t("Settings").message || "Settings"}</h1>
          </Grid.Column>
        </SubHeader>

        <Grid className="-contained-large">
          <Grid.Column width={16}>
            <Table celled>
              <Table.Body>
                <Table.Row>
                  <Table.Cell content="{{.Appointment.VIN}}" />
                  <Table.Cell content="{{.Customer.Title}}" />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content="{{.Appointment.WONr}}" />
                  <Table.Cell content="{{.Customer.FirstName}}" />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content="{{.Appointment.CarMake}}" />
                  <Table.Cell content="{{.Customer.Surname}}" />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content="{{.Appointment.CarModel}}" />
                  <Table.Cell content="{{.Link}}" />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content="{{.Appointment.RegNumber}}" />
                  <Table.Cell content="{{.Receptionist}}" />
                </Table.Row>
                <Table.Row>
                  <Table.Cell content={"{{.Date}}"} />
                  <Table.Cell />
                </Table.Row>
              </Table.Body>
            </Table>
            <Form style={{ padding: 25 }}>
              <Form.Field>
                <label>{t("Welcome text").message || "Welcome text"}</label>
                <UserInput
                  value={settings.welcome_text}
                  name="welcome_text"
                  placeholder={t("Welcome text").message || "Welcome text"}
                  onChange={this.handleInputChange}
                />
                {isSubmit && !settings.welcome_text && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{t("Success text").message || "Success text"}</label>
                <UserInput
                  value={settings.success_text}
                  name="success_text"
                  placeholder={t("Success text").message || "Success text"}
                  onChange={this.handleInputChange}
                />
                {isSubmit && !settings.success_text && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{t("repair_overview_welcome_text").message || "Welcome text repair overview page"}</label>
                <UserInput
                  value={settings.repair_overview_welcome_text}
                  name="repair_overview_welcome_text"
                  placeholder={t("repair_overview_welcome_text").message || "Welcome text repair overview page"}
                  onChange={this.handleInputChange}
                />
                {isSubmit && !settings.repair_overview_welcome_text && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{t("check_in_welcome_text").message || "Welcome text checkin page"}</label>
                <UserInput
                  value={settings.check_in_welcome_text}
                  name="check_in_welcome_text"
                  placeholder={t("check_in_welcome_text").message || "Welcome text checkin page"}
                  onChange={this.handleInputChange}
                />
                {isSubmit && !settings.check_in_welcome_text && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{t("check_in_success_text").message || "Success text checkin page"}</label>
                <UserInput
                  value={settings.check_in_success_text}
                  name="check_in_success_text"
                  placeholder={t("check_in_success_text").message || "Success text checkin page"}
                  onChange={this.handleInputChange}
                />
                {isSubmit && !settings.check_in_success_text && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{t("email").message || "Email"}</label>
                <UserInput value={settings.email} name="email" placeholder={t("email").message || "Email"} onChange={this.handleInputChange} />
                {isSubmit && !settings.email && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>

              <Form.Field>
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column width={4}>
                      <Checkbox
                        style={{ top: "10px" }}
                        toggle
                        name="check_in_testing_mode"
                        checked={settings.check_in_testing_mode}
                        onChange={this.handleCheckboxChange}
                        label={t("checkin_testing_mode").message || "Testing mode Check - in"}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} className="-no-padding-right">
                      <UserInput
                        name="check_in_testing_email"
                        value={settings.check_in_testing_email}
                        disabled={!settings.check_in_testing_mode}
                        placeholder={t("checkin_testing_email").message || "Testing email Check - in"}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} className="-no-padding-right">
                      <UserInput
                        name="check_in_testing_phone"
                        value={settings.check_in_testing_phone}
                        disabled={!settings.check_in_testing_mode}
                        placeholder={t("check_in_testing_phone").message || "Testing phone Check - in"}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column width={4}>
                      <Checkbox
                        style={{ top: "10px" }}
                        toggle
                        name="diagnose_cc_testing_mode"
                        checked={settings.diagnose_cc_testing_mode}
                        onChange={this.handleCheckboxChange}
                        label={t("diagnose_cc_testing_mode").message || "Testing mode Diagnose overview"}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} className="-no-padding-right">
                      <UserInput
                        name="diagnose_cc_testing_email"
                        disabled={!settings.diagnose_cc_testing_mode}
                        value={settings.diagnose_cc_testing_email}
                        placeholder={t("diagnose_cc_testing_email").message || "Testing email Diagnose overview"}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} className="-no-padding-right">
                      <UserInput
                        name="diagnose_cc_testing_phone"
                        disabled={!settings.diagnose_cc_testing_mode}
                        value={settings.diagnose_cc_testing_phone}
                        placeholder={t("diagnose_cc_testing_phone").message || "Testing phone Diagnose overview"}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row columns={2}>
                    <Grid.Column width={4}>
                      <Checkbox
                        style={{ top: "10px" }}
                        toggle
                        name="repair_cc_testing_mode"
                        checked={settings.repair_cc_testing_mode}
                        onChange={this.handleCheckboxChange}
                        label={t("repair_cc_testing_mode").message || "Testing mode Repair overview"}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} className="-no-padding-right">
                      <UserInput
                        name="repair_cc_testing_email"
                        disabled={!settings.repair_cc_testing_mode}
                        value={settings.repair_cc_testing_email}
                        placeholder={t("repair_cc_testing_email").message || "Testing email Repair overview"}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                    <Grid.Column width={6} className="-no-padding-right">
                      <UserInput
                        name="repair_cc_testing_phone"
                        disabled={!settings.repair_cc_testing_mode}
                        value={settings.repair_cc_testing_phone}
                        placeholder={t("repair_cc_testing_phone").message || "Testing phone Repair overview"}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>

              <Form.Field>
                <label>{t("phone").message || "Phone"}</label>
                <UserInput value={settings.phone_number} name="phone_number" placeholder={t("phone").message || "Phone"} onChange={this.handleInputChange} />
                {isSubmit && !settings.phone_number && (
                  <Label basic color="red" pointing>
                    {t("field_is_required").message || "This field is required."}
                  </Label>
                )}
              </Form.Field>
              <Form.Field>
                <label>{t("website").message || "Website"}</label>
                <UserInput value={settings.website} name="website" placeholder={t("website").message || "Website"} onChange={this.handleInputChange} />
              </Form.Field>
              <Form.Field>
                <label>{t("Color").message || "Color"}</label>
                <ColorPicker notPicker={true} color={settings.color} onChange={this.handleColorChange} />
              </Form.Field>
              <Form.Field>
                <label>{t("Layout").message || "Layout"}</label>
                <Dropdown
                  placeholder={t("Layout").message || "Layout"}
                  options={
                    (settings &&
                      settings.layouts &&
                      settings.layouts.map(l => {
                        return { text: l.description, value: l.id, key: l.id };
                      })) ||
                    []
                  }
                  selection
                  value={settings.layout}
                  name="layout"
                  label={t("Layout").message || "Layout"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("repair_overview_template").message || "Repair Overview Template"}</label>
                <Dropdown
                  selection
                  name="report_template_id"
                  value={settings.report_template_id}
                  options={reportTemplates}
                  placeholder={t("repair_overview_template").message || "Repair Overview Template"}
                  label={t("repair_overview_template").message || "Repair Overview Template"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("external_css").message || "External CSS"}</label>
                <UserInput
                  type="text"
                  value={settings.external_css}
                  name="external_css"
                  placeholder={t("external_css").message || "External CSS"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("external_js").message || "External JS"}</label>
                <UserInput
                  type="text"
                  value={settings.external_js}
                  name="external_js"
                  placeholder={t("external_js").message || "External JS"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>

              <Form.Field>
                <label>{t("checkin_days_prior").message || "Online Check-in days prior"}</label>
                <UserInput
                  type="number"
                  value={settings.check_in_days_prior}
                  name="check_in_days_prior"
                  placeholder={t("checkin_days_prior").message || "Online Check-in days prior"}
                  onChange={this.handleCheckInDaysPrior}
                />
              </Form.Field>

              <Form.Field>
                <label>{t("google_analytics_id").message || "Google Analytics ID"}</label>
                <UserInput
                  type="text"
                  value={settings.google_analytics_id}
                  name="google_analytics_id"
                  placeholder={t("google_analytics_id").message || "Google Analytics ID"}
                  onChange={this.handleInputChange}
                />
              </Form.Field>
              {desk_check_in_enabled && (
                <Can I="upload_welcome_pic_deskcheckin" the="files">
                  <Form.Field>
                    <label>{t("upload_desk_checkin_image").message || "Upload Desk Check-in start image (.jpg, .jpeg, .png) "}</label>
                    <input name="counter_tablet_home_url" accept="image/*" id="counter_tablet_home_url" type="file" onChange={this.handleImageChange} />
                    {typeof settings.counter_tablet_home_url === "string" && <Image src={settings.counter_tablet_home_url} size="medium" />}
                  </Form.Field>
                </Can>
              )}

              <Form.Field>
                <label>{this.props.t("sms").message || "SMS"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="is_sms_for_online_check_in_enabled"
                        checked={settings.is_sms_for_online_check_in_enabled}
                        onChange={this.handleCheckboxChange}
                        label={t("enable_send_sms_online_checkin").message || "Enable sending sms for online checkin"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>{this.props.t("confirmation_emails").message || "Confirmation Emails"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="send_confirmation_emails"
                        checked={settings.send_confirmation_emails}
                        onChange={this.handleCheckboxChange}
                        label={t("enable_send_confirmation_emails").message || "Enable sending confirmation emails"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>{t("prices").message || "Prices"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="hide_lease_intervention_price"
                        checked={settings.hide_lease_intervention_price}
                        onChange={this.handleCheckboxChange}
                        label={t("hide_lease_intervention_price").message || "Hide price of interventions for Lease appointments"}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="hide_internal_intervention_price"
                        checked={settings.hide_internal_intervention_price}
                        onChange={this.handleCheckboxChange}
                        label={t("hide_internal_intervention_price").message || "Hide price of internal interventions"}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="hide_warranty_intervention_price"
                        checked={settings.hide_warranty_intervention_price}
                        onChange={this.handleCheckboxChange}
                        label={t("hide_warranty_intervention_price").message || "Hide price of warranty interventions​"}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  {desk_check_in_enabled && (
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          toggle
                          name="hide_prices_in_desk_communication"
                          checked={settings.hide_prices_in_desk_communication}
                          onChange={this.handleCheckboxChange}
                          label={t("hide_price_for_desk_check_in_out").message || "Hide price for the desk check-in/out"}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )}

                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="block_automated_communication_for_lease_customer"
                        checked={settings.block_automated_communication_for_lease_customer}
                        onChange={this.handleCheckboxChange}
                        label={
                          t("dont_send_online_checkin_or_automated_email_to_lease").message || "Do not send Online check-in or other automated mail to Lease customers"
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              {is_keylocker_enabled && (
                <Form.Field>
                  <label>{this.props.t("key_lockers").message || "Key Lockers"}</label>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          toggle
                          name="online_check_in_send_pin"
                          checked={settings.online_check_in_send_pin}
                          onChange={this.handleCheckboxChange}
                          label={t("send_key_locker_pin_in_online_check_in").message || "Send Key Locker PIN in Online check-in"}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Checkbox
                          toggle
                          name="send_parking_gate_code"
                          checked={settings.send_parking_gate_code}
                          onChange={this.handleCheckboxChange}
                          label={t("send_parking_gate_code_in_online_check_in").message || "Send parking gate code in Online check-in"}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form.Field>
              )}

              <Form.Field>
                <label>{t("dont_fix_button").message || "Don't Fix Button"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="enable_not_fixed_advised"
                        checked={settings.enable_not_fixed_advised}
                        onChange={this.handleCheckboxChange}
                        label={t("enable_for_advised_items").message || "Enable for Advised Items"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="enable_not_fixed_critical"
                        checked={settings.enable_not_fixed_critical}
                        onChange={this.handleCheckboxChange}
                        label={t("enable_for_critical_items").message || "Enable for Critical Items"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="enable_not_fix_without_status"
                        checked={settings.enable_not_fix_without_status}
                        onChange={this.handleCheckboxChange}
                        label={t("enable_for_interventions_without_status").message || "Enabled for interventions without status"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="skip_optional_items"
                        checked={settings.skip_optional_items}
                        onChange={this.handleCheckboxChange}
                        label={t("skip_advised_items").message || "Skip Advised Items"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>{t("heading_position").message || "Heading position"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Radio
                        toggle
                        label={t("logo_and_info_header").message || "Logo and info in header"}
                        name="heading_position"
                        value={1}
                        checked={settings.heading_position === 1}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Radio
                        toggle
                        label={t("logo_and_info_footer").message || "Logo and info in footer"}
                        name="heading_position"
                        value={2}
                        checked={settings.heading_position === 2}
                        onChange={this.handleInputChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>{t("remarks").message || "Remarks"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="diagnose_overview_remarks_enabled"
                        checked={settings.diagnose_overview_remarks_enabled}
                        onChange={this.handleCheckboxChange}
                        label={t("diagnostic_overview_remark_enabled").message || "Enable additional remarks for diagnose overview"}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="checkin_remarks_enabled"
                        checked={settings.checkin_remarks_enabled}
                        onChange={this.handleCheckboxChange}
                        label={t("checkin_remark_enabled").message || "Enable remarks for online check-in"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>{t("customer_name_visible").message || "Customer name visible"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="online_check_in_name_visible"
                        checked={settings.online_check_in_name_visible}
                        onChange={this.handleCheckboxChange}
                        label={t("online_check_in_name_visible").message || "Enable for online check-in"}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="diagnose_overview_name_visible"
                        checked={settings.diagnose_overview_name_visible}
                        onChange={this.handleCheckboxChange}
                        label={t("diagnose_overview_name_visible").message || "Enable for diagnose overview"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Form.Field>
                <label>{t("scheduled_services").message || "Scheduled services"}</label>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Checkbox
                        toggle
                        name="display_intervention_elements"
                        checked={settings.display_intervention_elements}
                        onChange={this.handleCheckboxChange}
                        label={t("display_intervention_elements").message || "Display intervention elements"}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form.Field>
              <Can I="upload_logo_customcom" the="files">
                <Form.Field>
                  <label>{t("Upload logo").message || "Upload Logo (.jpg, .jpeg, .png)"}</label>
                  <input name="logo" id="logo" accept="image/*" type="file" onChange={this.handleImageChange} />
                  {typeof settings.logo === "string" && <Image src={settings.logo} width="250px" />}
                </Form.Field>
              </Can>

              <Can I="upload_welcome_pic_customcom" the="files">
                <Form.Field>
                  <label>
                    {t("Upload welcome picture").message || "Upload Welcome Picture (.jpg, .jpeg, .png) "}
                    <Popup
                      content={
                        t("Please provide a picture with a resolution of 197 x 460 pixels").message || "Please provide a picture with a resolution of 197 x 460 pixels"
                      }
                      trigger={<Icon name="exclamation triangle" color="red" />}
                    />
                  </label>
                  <input name="welcome_picture" accept="image/*" id="welcome_picture" type="file" onChange={this.handleImageChange} />
                  {typeof settings.welcome_picture === "string" && <Image src={settings.welcome_picture} />}
                </Form.Field>
              </Can>
              <Form.Field>
                <label>Support</label>
                <Checkbox toggle={true} label="Support Menu" name="support_enabled" checked={settings.support_enabled} onChange={this.handleCheckboxChange} />
              </Form.Field>
              <Form.Field>
                <AceEditor
                  mode="html"
                  theme="monokai"
                  onChange={this.handleSupportTextChange}
                  name="support_text"
                  width="100%"
                  value={settings.support_text}
                  fontSize={14}
                  showPrintMargin={false}
                  showGutter={true}
                  highlightActiveLine={true}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                    showLineNumbers: true,
                    tabSize: 2,
                  }}
                />
              </Form.Field>

              <Form.Group grouped>
                <label>{t("kiosk").message || "Kiosk"}</label>
                <Form.Field>
                  <Checkbox
                    checked={settings.is_customer_name_in_kiosk_checkin_enabled}
                    name="is_customer_name_in_kiosk_checkin_enabled"
                    label={t("customer_name_in_kiosk_checkin").message || "Customer name in kiosk checkin"}
                    onChange={this.handleKioskCheckinCheckboxChange}
                    toggle
                  />
                </Form.Field>

                <Form.Field>
                  <Checkbox
                    checked={settings.is_customer_signature_in_kiosk_checkin_enabled}
                    name="is_customer_signature_in_kiosk_checkin_enabled"
                    label={t("customer_signature_in_kiosk_checkin").message || "Customer signature in kiosk checkin"}
                    onChange={this.handleKioskCheckinCheckboxChange}
                    toggle
                  />
                </Form.Field>
              </Form.Group>

              <Form.Field>
                <Button disabled={isUploading} loading={isUploading} floated="right" color="green" onClick={this.submitSettings}>
                  {t("save").message || "SAVE"}
                </Button>
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { globalState: state.global };
};

const mapDispatchToProps = dispatch => {
  return {
    setAlert: alertOptions => dispatch(setAlert(alertOptions)),
  };
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CommunicationSettings));
