import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Form, Icon, Input, Modal as SemanticModal } from "semantic-ui-react";

import AppointmentLists from "./AppointmentLists";
import AppointmentNote from "./AppointmentNote";
import { APPOINTMENT_NOTE_TYPES } from "./util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faStickyNote, faCoffee } from "@fortawesome/free-solid-svg-icons";
import { faParking, faSteeringWheel } from "@fortawesome/pro-solid-svg-icons";

import "./DeskCheckinQuestionModal.scss";

class DeskCheckInQuestionModal extends Component {
  state = {
    customerWaiting: this.props.appointment.customer_waiting,
    selectedNote: null,
    woNoteId: null,
    temporaryDriverNoteId: null,
  };

  handleChangeCustomerWaiting = (_e, { checked: customerWaiting }) => {
    this.setState({ customerWaiting });
  };

  handleSelectNote = appointment_note_type_id => {
    let selectedNote = { appointment_note_type_id };

    if ([APPOINTMENT_NOTE_TYPES.MAIN, APPOINTMENT_NOTE_TYPES.PARKING].includes(appointment_note_type_id)) {
      const foundNote = this.props.appointment.notes?.find(note => note.appointment_note_type_id === appointment_note_type_id);
      if (foundNote) selectedNote = foundNote;
    }

    this.setState({ selectedNote });
  };

  handleSetNoteId = noteId => {
    const { selectedNote } = this.state;

    if (selectedNote.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.WO) this.setState({ woNoteId: noteId });
    else this.setState({ temporaryDriverNoteId: noteId });
  };

  handleCloseAppointmentNote = () => {
    this.setState({ selectedNote: null });
  };

  handleSendDeskCheckin = () => {
    if (this.state.customerWaiting !== this.props.appointment.customer_waiting) this.props.handleUpdateCustomerWaiting();

    this.props.renderDeskCheckInModal();
  };

  renderInputFields = () => {
    const { woNoteId, temporaryDriverNoteId, customerWaiting } = this.state;
    const { appointment, currentUser, isCarReadyOrQualityCheckDone, t } = this.props;

    const parkingNote = appointment.notes?.find(note => note.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.PARKING);
    const mainNote = appointment.notes?.find(note => note.appointment_note_type_id === APPOINTMENT_NOTE_TYPES.MAIN);
    const woNote = appointment.notes?.find(note => note.id === woNoteId);
    const temporaryDriverNote = appointment.notes?.find(note => note.id === temporaryDriverNoteId);

    const parkingNoteCreateEnabled =
      currentUser.acl["appointment-notes-parking-location"].some(acl => acl.activity === "create" || acl.activity === "update") && !isCarReadyOrQualityCheckDone;
    const mainNoteCreateEnabled =
      currentUser.acl["appointment-notes"].some(acl => acl.activity === "create" || acl.activity === "update") && !isCarReadyOrQualityCheckDone;
    const woNoteCreateEnabled = currentUser.acl["appointment-notes-wo"].some(acl => acl.activity === "create") && !isCarReadyOrQualityCheckDone;
    const temporaryDriverNoteCreateEnabled = currentUser.acl["appointment-notes-temp-driver"].some(acl => acl.activity === "create") && !isCarReadyOrQualityCheckDone;
    const setCustomerWaitingEnabled = currentUser.acl["appointments"].some(acl => acl.activity === "set-customer-waiting") && !isCarReadyOrQualityCheckDone;

    const woInputEnabled = woNoteCreateEnabled && !woNoteId;
    const temporaryDriverInputEnabled = temporaryDriverNoteCreateEnabled && !temporaryDriverNoteId;

    const getIcon = appointment_note_type_id => {
      switch (appointment_note_type_id) {
        case APPOINTMENT_NOTE_TYPES.WO:
          return <FontAwesomeIcon icon={faFileAlt} color="#4283CA" />;

        case APPOINTMENT_NOTE_TYPES.MAIN:
          return <FontAwesomeIcon icon={faStickyNote} color="red" />;

        case APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE:
          return <FontAwesomeIcon icon={faSteeringWheel} color="#000000" />;

        case APPOINTMENT_NOTE_TYPES.PARKING:
          return <FontAwesomeIcon icon={faParking} color="#4283CA" />;

        default:
          return <FontAwesomeIcon icon={faCoffee} color="#A5673F" />;
      }
    };

    return (
      <Form>
        <Form.Field className="input-container">
          {getIcon(APPOINTMENT_NOTE_TYPES.PARKING)}
          <Input
            readOnly
            value={parkingNote?.note || ""}
            placeholder={t("parking_location_note").message || "Parking Location Note"}
            onClick={parkingNoteCreateEnabled ? () => this.handleSelectNote(APPOINTMENT_NOTE_TYPES.PARKING) : null}
          />
        </Form.Field>

        <Form.Field className="input-container">
          {getIcon(APPOINTMENT_NOTE_TYPES.WO)}
          <Input
            readOnly
            value={woNote?.note || ""}
            placeholder={t("note").message || "Note"}
            onClick={woInputEnabled ? () => this.handleSelectNote(APPOINTMENT_NOTE_TYPES.WO) : null}
          />
        </Form.Field>

        <Form.Field className="input-container">
          {getIcon(APPOINTMENT_NOTE_TYPES.MAIN)}
          <Input
            readOnly
            value={mainNote?.note || ""}
            placeholder={t("main_note").message || "Main Note"}
            onClick={mainNoteCreateEnabled ? () => this.handleSelectNote(APPOINTMENT_NOTE_TYPES.MAIN) : null}
          />
        </Form.Field>

        <Form.Field className="input-container">
          {getIcon(APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE)}
          <Input
            readOnly
            value={temporaryDriverNote?.note || ""}
            onClick={temporaryDriverInputEnabled ? () => this.handleSelectNote(APPOINTMENT_NOTE_TYPES.TEMPORARY_DRIVER_NOTE) : null}
            placeholder={t("temporary_driver_note").message || "Temporary Driver Note"}
          />
        </Form.Field>

        <Form.Field className="input-container">
          {getIcon()}
          <span>{t("customer_waiting").message || "Customer Waiting"}?</span>
          <Checkbox checked={customerWaiting} onChange={this.handleChangeCustomerWaiting} disabled={isCarReadyOrQualityCheckDone || !setCustomerWaitingEnabled} />
        </Form.Field>
      </Form>
    );
  };

  render() {
    const { selectedNote } = this.state;

    const {
      t,
      dms_price_enabled,
      onInterventionDelete,
      isDeletingIntervention,
      appointment,
      dashboardConfig,
      checks,
      onInterventionAnswerUpdate,
      onChecklistAnswerUpdate,
      onMediaClick,
      onShowAddInterventionClick,
      isEditable,
      isPriceEditable,
      currentUser,
      openCarDetail,
      selectedDeskComm,
      isDeskCommunicationVisible,
      location,
      isCarReadyOrQualityCheckDone,
      getDeskComTotalPrice,
      handleCloseDeskCheckinQuestionsModal,
      onChangeDeskCommunication,
    } = this.props;

    return (
      <SemanticModal className="DeskCheckInQuestionModal" open={true} size="fullscreen" dimmer="blurring" closeOnDimmerClick={false} closeOnEscape>
        <SemanticModal.Header>
          {selectedNote && <AppointmentNote onSave={this.handleSetNoteId} onClose={this.handleCloseAppointmentNote} appointment={appointment} note={selectedNote} />}
          {this.renderInputFields()}
        </SemanticModal.Header>
        <SemanticModal.Content scrolling>
          <div className="AppointmentDetail__content__inner">
            <AppointmentLists
              dms_price_enabled={dms_price_enabled}
              onInterventionDelete={onInterventionDelete}
              isDeletingIntervention={isDeletingIntervention}
              appointment={appointment}
              dashboardConfig={dashboardConfig}
              interventions={appointment.interventions}
              checks={checks}
              selectedCustomerComm={selectedDeskComm}
              onInterventionAnswerUpdate={onInterventionAnswerUpdate}
              onChecklistAnswerUpdate={onChecklistAnswerUpdate}
              onMediaClick={onMediaClick}
              onShowAddInterventionClick={onShowAddInterventionClick}
              isEditable={isEditable}
              isPriceEditable={isPriceEditable}
              currentUser={currentUser}
              openCarDetail={openCarDetail}
              isCustomerCommunicationVisible={isDeskCommunicationVisible}
              onChangeCustomerCommunication={onChangeDeskCommunication}
              location={location}
            />
            <div className="TotalItems">
              {isCarReadyOrQualityCheckDone ? (
                <h5>
                  {t("total_fixed_items").message || "Total amount of items fixed by mechanic"}: {getDeskComTotalPrice(true)} €
                </h5>
              ) : (
                <h5>
                  {t("total_selected_items").message || "Total amount of selected items"}: {getDeskComTotalPrice(false)} €
                </h5>
              )}
            </div>
          </div>
        </SemanticModal.Content>
        <SemanticModal.Actions>
          <Button color="red" onClick={handleCloseDeskCheckinQuestionsModal} floated="left">
            <Icon name="close" /> {t("close").message || "CLOSE"}
          </Button>

          <Button color="green" onClick={this.handleSendDeskCheckin}>
            <Icon name="checkmark" /> {t("send").message || "Send"}
          </Button>
        </SemanticModal.Actions>
      </SemanticModal>
    );
  }
}

export default withTranslation()(DeskCheckInQuestionModal);
