import React from "react";
import { Button } from "semantic-ui-react";
import ReactTable, { ReactTableDefaults } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { saveAs } from "file-saver";
import moment from "moment";

const TyreTeamOverview = ({ report, t }) => {
  if (!Array.isArray(report.report_data) || report.report_data.length < 1) {
    return (
      <>
        <h3 className="report-heading">{t(report.report_name).message || report.report_name}</h3>
        <p className="no-data">{t("no_data").message || "No data"}</p>
      </>
    );
  }

  const data = report.report_data.map(d => JSON.parse(d));

  const exportData = async () => {
    let csv = "";
    let header = (t("location").message || "Location") + ", ";
    header += (t("offered").message || "Offered") + ", ";
    header += (t("ordered").message || "Ordered") + ", ";
    header += (t("mounted").message || "Mounted") + ", ";

    let values = data
      .map(d => {
        let v = d.location + ", " + d.offered + ", " + d.ordered + ", " + d.mounted + ", ";

        return v;
      })
      .join("\n");

    csv += header + "\n" + values;
    saveAs(new File([csv], { type: "text/csv;charset=utf-8" }), "TyreTeamOverview" + moment().format("YYYYMMDD") + ".csv");
  };

  return (
    <>
      <h3 className="report-heading">
        {t(report.report_name).message || report.report_name}
        <Button color="green" floated="right" basic onClick={() => exportData()}>
          <span style={{ marginRight: "10px" }}>{t("export").message || "Export"}</span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      </h3>
      <div className="dealer-reports-table-container">
        <ReactTable
          className="ReactTable -floated-table no-overflow all-data-table -initial-margin"
          data={data}
          showPagination={false}
          showPageSizeOptions={false}
          sortable={false}
          style={{ maxHeight: "600px" }}
          resizable={false}
          defaultPageSize={data.length || 0}
          pageSize={data.length || 0}
          noDataText={
            <div className="Table__no-results">
              <p>{t("no_data").message || "No data"}</p>
            </div>
          }
          column={{
            ...ReactTableDefaults.column,
            headerClassName: "ReactTable__column-header dealer-table-header",
            className: "ReactTable__column",
          }}
          columns={[
            {
              Header: <strong>{t("location").message || "Location"}</strong>,
              accessor: "location",
            },
            {
              Header: <strong>{t("offered").message || "Offered"}</strong>,
              accessor: "offered",
            },
            {
              Header: <strong>{t("ordered").message || "Ordered"}</strong>,
              accessor: "ordered",
            },
            {
              Header: <strong>{t("mounted").message || "Mounted"}</strong>,
              accessor: "mounted",
            },
          ]}
        />
      </div>
    </>
  );
};

export { TyreTeamOverview };
