import moment from "moment";
import { ORDER_BY } from ".";
import { PIN_STATUS } from "../../components/Pin/enum";
import Service from "./service";

export const WARRANTY_ACTION_TYPES = {
  GET_PIN_ITEMS: "warranty/GET_PIN_ITEMS",
  GET_PIN_ITEMS_SUCCESS: "warranty/GET_PIN_ITEMS_SUCCESS",
  GET_PIN_ITEMS_FAIL: "warranty/GET_PIN_ITEMS_FAIL",
  UPDATE_PIN_ITEMS: "warranty/UPDATE_PIN_ITEMS",
  RESET_PIN_ITEMS: "warranty/RESET_PIN_ITEMS",
  WEB_SOCKET_UPDATE: "warranty/WEB_SOCKET_UPDATE",
  WEB_SOCKET_UPDATE_APPLIED: "warranty/WEB_SOCKET_UPDATE_APPLIED",
  NO_UPDATE_TO_APPLY: "warranty/NO_UPDATE_TO_APPLY",
};

export function getPinItems(requestData) {
  return dispatch => {
    dispatch({ type: WARRANTY_ACTION_TYPES.GET_PIN_ITEMS });

    if (!requestData.location_ids.length && !requestData.dealer_ids.length) {
      return dispatch({ type: WARRANTY_ACTION_TYPES.RESET_PIN_ITEMS });
    }

    let page = requestData.page || 1;

    Service.getPins({ ...requestData, page })
      .then(result => {
        const data = result?.data?.data ? result?.data?.data : { items: [], nb_pages: 0, nb_items: 0 };
        let { items, nb_pages, nb_items } = data;

        if (!items) items = [];

        let interventions = [];
        let questions = [];
        let interventions_offsets = [];
        let questions_offsets = [];
        let intervention_offset = 0;
        let question_offset = 0;
        items.forEach(i => {
          if (i.intervention_id) {
            if (interventions_offsets[i.intervention_id] === undefined) {
              interventions_offsets[i.intervention_id] = intervention_offset++;
              interventions[interventions_offsets[i.intervention_id]] = { ...i, history: [i] };
            } else {
              if (i.created_on > interventions[interventions_offsets[i.intervention_id]].created_on)
                interventions[interventions_offsets[i.intervention_id]] = { ...i, history: interventions[interventions_offsets[i.intervention_id]].history.concat(i) };
              else interventions[interventions_offsets[i.intervention_id]].history.push(i);
            }
          } else if (i.question_result_id) {
            if (questions_offsets[i.question_result_id] === undefined) {
              questions_offsets[i.question_result_id] = question_offset++;
              questions[questions_offsets[i.question_result_id]] = { ...i, history: [i] };
            } else {
              if (i.created_on > questions[questions_offsets[i.question_result_id]].created_on)
                questions[questions_offsets[i.question_result_id]] = { ...i, history: questions[questions_offsets[i.question_result_id]].history.concat(i) };
              else questions[questions_offsets[i.question_result_id]].history.push(i);
            }
          }
        });

        const grouped = [...interventions, ...questions].sort((a, b) => {
          if (requestData.order_by === ORDER_BY.CLAIM_NR_ASCENDING) return a.claim_nr.localeCompare(b.claim_nr);
          if (requestData.order_by === ORDER_BY.CLAIM_NR_DESCENDING) return b.claim_nr.localeCompare(a.claim_nr);

          if (requestData.order_by === ORDER_BY.PIN_UPDATED_ON_ASCENDING) return a.updated_on.localeCompare(b.updated_on);
          if (requestData.order_by === ORDER_BY.PIN_UPDATED_ON_DESCENDING) return b.updated_on.localeCompare(a.updated_on);

          if (requestData.order_by === ORDER_BY.APPOINTMENT_DATE_ASCENDING) return a.appointment.time_car_app.localeCompare(b.appointment.time_car_app);
          if (requestData.order_by === ORDER_BY.APPOINTMENT_DATE_DESCENDING) return b.appointment.time_car_app.localeCompare(a.appointment.time_car_app);
        });

        return dispatch({ type: WARRANTY_ACTION_TYPES.GET_PIN_ITEMS_SUCCESS, items: grouped, nb_items, nb_pages, page });
      })
      .catch(error => {
        const errorMessage = typeof error?.response?.data !== "string" ? error.response.data.errors[0] : "Query history failed to load";
        dispatch({ type: WARRANTY_ACTION_TYPES.GET_PIN_ITEMS_FAIL, errorMessage });
      });
  };
}

export function pinUpdated(pinLog) {
  return (dispatch, getState) => {
    let items = getState().warranty.items;

    const updatedItem = pinLog[0];

    items = items.map(i => {
      if ((i.question_result_id && i.question_result_id === updatedItem.question_result_id) || (i.intervention_id && i.intervention_id === updatedItem.intervention_id)) {
        return { ...updatedItem, mechanic_fixed: i.mechanic_fixed, history: pinLog };
      }

      return i;
    });

    dispatch({ type: WARRANTY_ACTION_TYPES.UPDATE_PIN_ITEMS, items });
  };
}

export function pinDeleted(groupedItem, reason) {
  return (dispatch, getState) => {
    const user = getState().auth.user;

    const newLastLog = {
      ...groupedItem.history[0],
      pin_status_id: PIN_STATUS.DELETED,
      user_id: user.id,
      user,
      note: reason,
      created_on: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
    };

    newLastLog.id++;

    groupedItem = { ...groupedItem, ...newLastLog, history: [newLastLog, ...groupedItem.history] };
    if (groupedItem.question_result) groupedItem.question_result.pinned = false;
    if (groupedItem.intervention) groupedItem.intervention.pinned = false;

    const items = getState().warranty.items.map(i => {
      if ((i.question_result_id && i.question_result_id === groupedItem.question_result_id) || (i.intervention_id && i.intervention_id === groupedItem.intervention_id)) {
        return groupedItem;
      }

      return i;
    });

    dispatch({ type: WARRANTY_ACTION_TYPES.UPDATE_PIN_ITEMS, items });
  };
}

export function websocketWarrantyUpdate(payload) {
  const { _topic, body } = payload;

  return dispatch => {
    if (!body.update) return dispatch(noUpdateToApply());

    switch (_topic) {
      case "PinUpdateMessage":
        return dispatch(websocketPinUpdate(body.update));

      case "QuestionResultUpdateMessage":
        return dispatch(websocketQuestionResultUpdate(body));

      case "InterventionUpdatedMessage":
        return dispatch(websocketInterventionUpdate(body.update));

      case "AppointmentUpdatedMessage":
        return dispatch(websocketAppointmentUpdate(body));

      case "PinDeleteMessage":
        return dispatch(webSocketPinDelete(body.update));

      default:
        return dispatch(noUpdateToApply());
    }
  };
}

export function webSocketPinDelete(update) {
  return (dispatch, getState) => {
    dispatch({
      type: WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE_APPLIED,
      items: getState().warranty.items.filter(
        i => (i.question_result_id && i.question_result_id !== update.question_result_id) || (i.intervention_id && i.intervention_id !== update.intervention_id)
      ),
    });
  };
}

export function websocketPinUpdate(update) {
  return (dispatch, getState) => {
    let items = [...getState().warranty.items];

    const pinIdx = items.findIndex(
      i => (i.question_result_id && i.question_result_id === update.question_result_id) || (i.intervention_id && i.intervention_id === update.intervention_id)
    );

    if (pinIdx > -1) {
      items[pinIdx] = { ...items[pinIdx], ...update };
      const historyIdx = items[pinIdx].history?.findIndex(h => h.id === update.id);

      if (historyIdx > -1) items[pinIdx].history[historyIdx] = { ...items[pinIdx].history[historyIdx], ...update };
      else items[pinIdx].history = [update];
    }

    dispatch({ type: WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE_APPLIED, items });
  };
}

export function websocketQuestionResultUpdate(body) {
  return (dispatch, getState) => {
    let items = [...getState().warranty.items];

    const pinIdx = items.findIndex(i => i.question_result_id === body.question_result_id);

    if (pinIdx > -1) items[pinIdx].question_result = { ...items[pinIdx].question_result, ...body.update };

    dispatch({ type: WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE_APPLIED, items });
  };
}

export function websocketInterventionUpdate(update) {
  return (dispatch, getState) => {
    const items = getState().warranty.items.map(item => {
      const intervention = update.find(i => i.id === item.intervention_id);
      if (intervention) return { ...item, intervention: { ...item.intervention, ...intervention } };

      return item;
    });

    dispatch({ type: WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE_APPLIED, items });
  };
}

export function websocketAppointmentUpdate(body) {
  return (dispatch, getState) => {
    const items = getState().warranty.items.map(i => {
      if (i.appointment_id === body.appointment_id) {
        i.appointment = { ...i.appointment, ...body.update };

        if (body.update.appointment_status) {
          i.appointment.appointment_status_identifier = body.update.appointment_status.identifier;
        }
      }

      return i;
    });

    dispatch({ type: WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE_APPLIED, items });
  };
}

export function resetPinItems() {
  return dispatch => dispatch({ type: WARRANTY_ACTION_TYPES.RESET_PIN_ITEMS });
}

export function noUpdateToApply() {
  return dispatch => dispatch({ type: WARRANTY_ACTION_TYPES.NO_UPDATE_TO_APPLY });
}

const INITIAL_STATE = {
  items: [],
  page: 0,
  nb_pages: null,
  isLoadingPins: false,
  actionType: "",
  errorMessage: "",
  webSocketUpdate: null,
};

const warrantyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WARRANTY_ACTION_TYPES.GET_PIN_ITEMS:
      return {
        ...state,
        actionType: action.type,
        isLoadingPins: true,
      };
    case WARRANTY_ACTION_TYPES.GET_PIN_ITEMS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        items: action.items,
        nb_pages: action.nb_pages,
        nb_items: action.nb_items,
        page: action.page,
        isLoadingPins: false,
      };
    case WARRANTY_ACTION_TYPES.GET_PIN_ITEMS_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessage: action.errorMessage,
        isLoadingPins: false,
      };
    case WARRANTY_ACTION_TYPES.RESET_PIN_ITEMS:
      return {
        ...state,
        actionType: action.type,
        items: [],
        nb_pages: null,
        isLoadingPins: false,
      };
    case WARRANTY_ACTION_TYPES.UPDATE_PIN_ITEMS:
      return {
        ...state,
        actionType: action.type,
        items: action.items,
      };
    case WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE:
      return {
        ...state,
        actionType: action.type,
        webSocketUpdate: action.webSocketUpdate,
      };
    case WARRANTY_ACTION_TYPES.WEB_SOCKET_UPDATE_APPLIED:
      return {
        ...state,
        actionType: action.type,
        items: action.items,
      };
    case WARRANTY_ACTION_TYPES.NO_UPDATE_TO_APPLY:
      return {
        ...state,
        actionType: action.type,
      };
    case "persist/REHYDRATE":
      const incoming = action.payload;
      if (incoming?.warranty) {
        return {
          ...state,
          ...incoming.warranty,
          actionType: state.actionType,
          errorMessage: state.errorMessage,
          items: state.items,
        };
      }
      return state;
    default:
      return state;
  }
};

export default warrantyReducer;
