import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }
  static getUser(id) {
    return axios.post(`${ENV.apiBaseB}/users/details`, { id: id }, this.requestConfig());
  }

  static getUserMetrics(id) {
    return axios.post(ENV.systemBase + "/support/list", { user_id: id }, this.requestConfig());
  }

  static searchUsers(requestData) {
    return axios.post(ENV.apiBaseB + "/users/search", requestData, this.requestConfig());
  }

  static getDevicesByUser(requestData) {
    return axios.post(ENV.systemBase + "/devices/find_by_user", requestData, this.requestConfig());
  }

  static reinstateUser(requestData) {
    return axios.post(ENV.apiBaseB + "/iam/reinstate_user", requestData, this.requestConfig());
  }

  static setDealerDataOfficer(requestData) {
    return axios.post(ENV.apiBaseB + "/agreements/set_dealer_data_officer", requestData, this.requestConfig());
  }

  static getACLRoles() {
    return axios.get(ENV.apiBaseB + "/iam/acl_roles", this.requestConfig());
  }

  static updateUser(requestData) {
    return axios.post(ENV.apiBaseB + "/users/update", requestData, this.requestConfig());
  }

  static deleteUser(id) {
    return axios.post(ENV.apiBaseB + "/users/delete", { id: id }, this.requestConfig());
  }

  static createUser(requestData) {
    return axios.post(ENV.apiBaseB + "/users/create", requestData, this.requestConfig());
  }

  static getBrands() {
    return axios.get(ENV.apiBaseB + "/brands/list", this.requestConfig());
  }

  static getVersions() {
    return axios.get(ENV.apiBaseB + "/users/list_versions", this.requestConfig());
  }

  static getOneiPlanningMechanicList(dealer_location_id) {
    return axios.post(ENV.apiBaseB + "/users/list_onei_planning_monteur", { dealer_location_id }, this.requestConfig());
  }

  static getPlanItMechanicList(dealer_location_id) {
    return axios.post(ENV.apiBaseB + "/users/list_planit_planning_mechanic", { dealer_location_id }, this.requestConfig());
  }

  static getDMSUsers(dealer_location_id) {
    return axios.post(ENV.apiBaseB + "/users/list_dms_users", { dealer_location_id }, this.requestConfig());
  }

  static updatePlanitPairing(requestData) {
    return axios.post(ENV.apiBaseB + "/users/update_pairing/planit", requestData, this.requestConfig());
  }

  static updateOneiPairing(requestData) {
    return axios.post(ENV.apiBaseB + "/users/update_pairing/onei", requestData, this.requestConfig());
  }

  static updateDMSPairing(requestData) {
    return axios.post(ENV.apiBaseB + "/users/update_pairing/dms", requestData, this.requestConfig());
  }
}

export default Service;
